import housedAsinsApi from "api/housedAsins"
import { useRef, useState, useCallback, useEffect, createContext, FC, PropsWithChildren, useContext } from "react"

const useSyncHoused = () => {
  const syncStarted = useRef(false)
  const [synced, setSynced] = useState<boolean>(false)
  const [remaining, setRemaining] = useState<number | null>(null)

  const sync = useCallback((force = false) => {
    housedAsinsApi.resyncHoused(force).then(({synced, remaining}) => {
        setSynced(synced)
        if (!synced) {
          setRemaining(remaining)
          return sync()
        } else {
          setRemaining(null)
          syncStarted.current = false
        }
      })
  }, [])

  useEffect(() => {
    if (syncStarted.current) return
    syncStarted.current = true
    sync()
  }, [sync])

  return {
    sync,
    synced,
    remaining,
  }
}

const HousedSyncContext = createContext<ReturnType<typeof useSyncHoused>>({ synced: false, sync: () => {}, remaining: null })

export const useHousedSync = () => useContext(HousedSyncContext)

const  HousedSyncProvider: FC<PropsWithChildren> = ({ children }) => {
  const syncState = useSyncHoused()

  return (
    <HousedSyncContext.Provider value={syncState}>
      {children}
    </HousedSyncContext.Provider>
  )
}

export default HousedSyncProvider