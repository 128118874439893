import { Row } from '@tanstack/react-table'
import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import Checkbox from 'components/Checkbox'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TD from 'components/tables/TD'
import TR from 'components/tables/TR'
import useTableContext from 'contexts/Table/useTableContext'
import { FC, useMemo } from 'react'
import { RowRenderer } from 'types/tables'
import { IWarehouseInventoryItem } from 'types/warehouse'
import { usNumber } from 'utils/formatting'

interface props {
  row: Row<IWarehouseInventoryItem>
}

const Item: FC<props> = ({ row }) => {
  const item = row.original
  const listing = `https://www.amazon.com/dp/${item.asin}`
  const {select} = useTableContext()

  const expirationDate = useMemo(() => {
    if (!item.expirationDate) return ''
    const date = new Date(item.expirationDate)
    const [month, day, year] = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-').split('-')
    return `${year}-${month}-${day}`
  }, [item.expirationDate])

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<IWarehouseInventoryItem> = {
    selection: (cell) => (
      !!select && (
        <td key={cell.id} onClick={(e) => {
            e.stopPropagation()
            select?.onSelectClick(item.id)
          }} style={{...getCommonPinningStyles(cell.column)}}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      )),
    image: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item?.asin} size={32} imageSize={32} />
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item?.asin} readOnly />
          {!!item.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
        </div>
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.title} readOnly />
      </td>
    ),
    upc: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.upc} readOnly />
      </td>
    ),
    units: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.units} readOnly />
      </td>
    ),
    bundle: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.bundle} readOnly />
      </td>
    ),
    sellable: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.sellable} readOnly />
      </td>
    ),
    prepCost_name: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect selected={item.prepCost_name || ''} badges={[]} onSelect={() => {}} singleColor="blue" editable={false} />
          <span>${usNumber(+item.prepCost_amount)}</span>
        </div>
      </td>
    ),
    expirationDate: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.expirationDate ? <input type="date" value={expirationDate} readOnly /> : '...'}
      </td>
    ),
  }

  return <TR>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default Item
