import { ColumnDef } from "@tanstack/react-table";
import { MasterUPCCatalogItem } from "types/housedAsins";

const columnDef: ColumnDef<MasterUPCCatalogItem>[] = [
    {
        id: "supplier",
        header: "Supplier",
        meta: {
            filters: "string",
        }
    },
    {
        id: "title",
        header: "Title",
        meta: {
            filters: "string",
        }
    },
    {
        id: "size",
        header: "Size",
        meta: {
            filters: "number",
        }
    },
    {
        id: "uom",
        header: "UOM",
        meta: {
            filters: "string",
        }
    },
    {
        id: "upc",
        header: "UPC",
        meta: {
            filters: "string",
        }
    },
    {
        id: "vendorSKU",
        header: "Vendor SKU",
        meta: {
            filters: "string",
        }
    },
    {
        id: "unitCost",
        header: "Unit Cost",
        meta: {
            filters: "number",
        }
    },
    {
        id: "casePack",
        header: "Case Pack",
        meta: {
            filters: "number",
        }
    },
    {
        id: "caseCost",
        header: "Case Cost",
        meta: {
            filters: "number",
        }
    }
]

export default columnDef;