import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import useSupplierNames from 'hooks/useSupplierNames'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { CreateWorkingPOInput, HousedAsin } from 'types/housedAsins'
import { usNumber } from 'utils/formatting'

interface Item {
  id: string
  asin: string
  sku: string
  upc: string
  buyQty: number
  unitCost: number
  targetPrice: number
  supplier: string
  notes: string
  bundle: number
  caseQty: number
  targetSoldPrice: number | null
  order_type: "Backorder" | "Stocked"
}

const validate = (inputs: CreateWorkingPOInput[]) => {
  inputs.forEach((input) => {
    if (!input.buyQty) throw new Error('Buy Qty is required')
    if (!input.unitCost) throw new Error('Unit Cost is required')
    if (!input.targetPrice) throw new Error('Ask Price is required')
    input.buyQty = parseInt(input.buyQty as unknown as string)
    if (isNaN(input.buyQty)) throw new Error('Buy Qty must be a number')
    input.unitCost = Number(input.unitCost)
    if (isNaN(input.unitCost)) throw new Error('Unit Cost must be a number')
    input.targetPrice = Number(input.targetPrice)
    if (isNaN(input.targetPrice)) throw new Error('Ask Price must be a number')
  })
  return inputs
}

const CreateWorkingPO: AwaitableModal<undefined, HousedAsin[]> = ({ resolve, open, onCancel, initialData }) => {
  const { supplierNames } = useSupplierNames()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [items, setItems] = useState<Item[]>([])

  useEffect(() => {
    if (!open) return
    if (initialData) {
      setItems(
        initialData.map((item) => ({
          id: item.id,
          asin: item.asin,
          sku: item.sku || "",
          upc: item.upc || "",
          buyQty: item.sellable * item.bundle || 0,
          targetPrice: item.targetPrice || item.unitCost || 0,
          unitCost: item.unitCost || 0,
          supplier: supplierNames?.includes(item.supplier as string) ? (item.supplier as string) : '',
          notes: '',
          bundle: item.bundle || 1,
          caseQty: item.caseQty || 1,
          targetSoldPrice: null,
          order_type: item.order_type,
        }))
      )
    }
  }, [open, initialData, supplierNames])

  const handleSubmit = () => {
    if (submitting) return
    setSubmitting(true)
    try {
      const validatedInputs = validate(items)
      housedAsinsApi
        .createWorkingPurchaseOrders(validatedInputs)
        .then(() => {
          handleError("Added to working POs")
          resolve()
        })
        .finally(() => setSubmitting(false))
    } catch (e: any) {
      handleError(e.message)
      setSubmitting(false)
    }
  }

  const close = () => {
    if (submitting) return
    onCancel()
  }

  return (
    <Modal open={open} close={close}>
      <div className="w-full rounded-xl max-w-[calc(100vw-4rem)] bg-surface-primary max-h-[calc(100vh-4rem)] h-max min-w-[16rem] overflow-y-auto">
        <div className="flex items-start justify-start p-6 pb-4 gap-2">
          <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
          <div className="flex flex-col items-start gap-1">
            <span className="text-base text-text-primary font-medium">Create a Purchase Order</span>
            <span className="text-sm text-text-secondary">Input information.</span>
          </div>
        </div>
        <main className="overflow-y-auto flex w-full h-full">
          <div className="flex flex-col bg-surface-primary px-6 gap-2">
            <div className="flex flex-col gap-4 pb-6 border-y border-y-border-primary divide-y divide-y-border-secondary">
              {items.map((item, index) => (
                <div key={item.id} className="items-end gap-4 grid grid-cols-4 pt-8">
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">ASIN</span>
                    <div className="flex gap-2 items-center">
                      <AmazonProductImage asin={item.asin} className="w-8 h-8" size={96} />
                      <input type="text" value={item.asin} className="border border-border-primary py-2.5 px-2 rounded-lg outline-none max-w-64 w-full" readOnly placeholder="ASIN" />
                      {!!item.asin && (
                        <a href={`https://amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
                          <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">MSKU</span>
                    <input type="text" value={item.sku} className="border border-border-primary py-2.5 px-2 rounded-lg outline-none" readOnly placeholder="MSKU" />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">UPC</span>
                    <input type="text" value={item.upc} className="border border-border-primary py-2.5 px-2 rounded-lg outline-none" readOnly placeholder="UPC" />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Supplier</span>
                    <select className="border border-border-primary py-2.5 px-2 rounded-lg outline-none" value={item.supplier} disabled>
                      <option value="">Missing Supplier</option>
                      {supplierNames?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Unit Cost</span>
                    <input
                      type="text"
                      value={item.unitCost}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, unitCost: e.target.value as unknown as number } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      // readOnly={!item.manuallyAdded}
                      placeholder="Unit Cost"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 w-full">
                    <div className="flex flex-col gap-1 w-full">
                      <span className="text-xs text-text-secondary font-medium">Buy Qty (Bundle QTY: {item.bundle})</span>
                      <input
                        type="text"
                        value={item.buyQty}
                        onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, buyQty: e.target.value as unknown as number } : p)))}
                        className="border border-border-primary py-2.5 px-2 rounded-lg outline-none max-w-40"
                        placeholder="Buy Qty"
                      />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <span className="text-xs text-text-secondary font-medium">Cases (Case QTY: {item.caseQty})</span>
                      <input
                        type="text"
                        value={usNumber(item.buyQty / item.caseQty, 0)}
                        readOnly
                        className="border border-border-primary py-2.5 px-2 rounded-lg outline-none max-w-40"
                        placeholder="Case Qty"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Ask Price</span>
                    <input
                      type="text"
                      value={item.targetPrice}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, targetPrice: e.target.value as unknown as number } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      placeholder="Unit Cost"
                    />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <span className="text-xs text-text-secondary font-medium">Notes</span>
                    <input
                      type="text"
                      value={item.notes}
                      onChange={(e) => setItems((prev) => prev.map((p, i) => (i === index ? { ...p, notes: e.target.value } : p)))}
                      className="border border-border-primary py-2.5 px-2 rounded-lg outline-none"
                      placeholder="Notes"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
        <div className="flex items-center gap-4 p-4 w-full">
          <button className="button-secondary grow" disabled={submitting} onClick={() => close()}>
            Cancel
          </button>
          <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CreateWorkingPO
