import { ColumnDef } from '@tanstack/react-table'
import { HousedAsin } from 'types/housedAsins'

const columnDef: ColumnDef<HousedAsin & { image: string }>[] = [
  {
    id: 'selection',
    header: '',
    size: 124,
  },
  {
    id: 'image',
    header: 'Image',
    size: 60,
  },
  {
    id: 'asin',
    header: 'ASIN',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'title',
    header: 'Title',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'supplier',
    header: 'Supplier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'upc',
    header: 'UPC',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'vendorSKU',
    header: 'Vendor SKU',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'sku',
    header: 'MSKU',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'fba',
    header: 'Is FBA',
  },
  {
    id: 'created_at',
    header: 'Added At',
    meta: {
      filters: 'date',
    },
  },
  {
    id: 'caseQty',
    header: 'Case Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'unitCost',
    header: 'Unit Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'bundle',
    header: 'Bundle Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'sellable',
    header: 'Sellable Qty',
    meta: {
      filters: 'sellable',
    },
  },
  {
    id: 'buyer',
    header: 'Buyer',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'order_type',
    header: 'Order Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'prepCost',
    header: 'Prep Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'asinCost',
    header: 'ASIN Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'shippingCost',
    header: 'Shipping Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'landedCost',
    header: 'Landed Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'price',
    header: 'Current Price',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'targetPrice',
    header: 'Target Sold Price',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'net',
    header: 'Net Proceeds',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'gross',
    header: 'Profit per Unit',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'totalGross',
    header: 'Total Profit',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'margin',
    header: 'Margin',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'roi',
    header: 'ROI',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'salesRank',
    header: 'Rank',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'dimensionTier',
    header: 'Product Dimensions Tier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'storageType',
    header: 'Storage Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'weightValue',
    header: 'Weight',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'dimensions',
    header: 'Dimensions',
  },
  {
    id: 'activeListing',
    header: 'Active Listing',
    meta: {
      filters: 'boolean',
    }
  },
  {
    id: 'filledOut',
    header: 'Data Entry Finished',
    meta: {
      filters: 'boolean',
    }
  },
]

export default columnDef
