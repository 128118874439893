import { FC } from 'react'
import { getCommonPinningStyles } from './pinningStyles'
import { Row } from '@tanstack/react-table'

interface props {
  row: Row<undefined>
}

const TableLoadingRow: FC<props> = ({ row }) => {
  return (
    <tr key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
          <div className="animate-pulse h-6 bg-surface-secondary rounded-md w-full" />
        </td>
      ))}
    </tr>
  )
}

export default TableLoadingRow
