import { FC, FormEvent, useState } from 'react'
import { AsinEntryItem } from 'types/housedAsins'
import Icon from 'assets/icons/iconset'
import housedAsinsApi from 'api/housedAsins'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import BadgeSelect from 'components/BadgeSelect'
import { IPrepCost } from 'types/prepCosts'
import { usNumber } from 'utils/formatting'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TR from 'components/tables/TR'
import { IBuyer } from 'types/buyers'

interface props {
  row: Row<AsinEntryItem>
  onDone: (supplier?: AsinEntryItem) => void
  onRemove: (id: string) => void
  selected?: boolean
  handleSelect?: (id: string) => void
}

const Item: FC<props> = ({ onDone, row }) => {
  const item = row.original
  const {locked, extra, select} = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...item,
  } as AsinEntryItem)
  const [editingWebsite, setEditingWebsite] = useState(false)
  const { supplierNames, prepCosts } = extra || {}
  const availableBuyers = extra?.availableBuyers || []

  const onSubmit = (e?: FormEvent, overrides?: Partial<AsinEntryItem>) => {
    if (locked) return false
    e?.preventDefault()
    if (submitting) return
    setSubmitting(true)
    housedAsinsApi
      .updateAsinEntry(item.id, { ...values, ...overrides })
      .then(() => {
        onDone({ ...values })
        setEditingWebsite(false)
      })
      .finally(() => setSubmitting(false))
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return () => {
      if (locked) return
      setValues((old) => ({ ...old, [key]: value }))
    }
  }

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<AsinEntryItem> = {
    selection: (cell) => (
      !!select && (
        <td key={cell.id} onClick={() => select?.onSelectClick(item.id)} style={{...getCommonPinningStyles(cell.column)}}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      )),
    amzLink: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <div className="flex items-center gap-2">
          {values.amzLink && (!editingWebsite || submitting || locked) ? (
            <a href={values.amzLink} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          ) : (
            <input type="text" className='table-input' readOnly={locked} value={values.amzLink} onBlur={onSubmit} onChange={(e) => onValueChange('amzLink', e.target.value)()} />
          )}
          {values.amzLink && !editingWebsite && !locked && (
            <button className="p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary" onClick={() => setEditingWebsite(true)}>
              <Icon name="CaretDoubleLeft" className="group-hover:opacity-100 opacity-0 transition-all w-4 h-4" />
            </button>
          )}
        </div>
      </td>
    ),
    supplier: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} className="!min-w-[8rem]" style={{...getCommonPinningStyles(cell.column)}}>
        <BadgeSelect
          selected={values.supplier || ''}
          badges={supplierNames}
          onSelect={(supplier) => {
            setValues((old) => ({ ...old, supplier }))
            onSubmit(undefined, { supplier })
          }}
          editable={!locked && !submitting}
        />
      </td>
    ),
    currentCost: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <div className="relative">
          <input
            type="text"
            readOnly={locked}
            value={values.currentCost}
            onBlur={onSubmit}
            onChange={(e) => onValueChange('currentCost', Number(e.target.value))()}
            placeholder="0.00"
            className="!pl-5 table-input"
          />
          <span className="text-sm text-base-900 p-2 absolute left-0">$</span>
        </div>
      </td>
    ),
    upc: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <input readOnly={locked} type="text" className="table-input" placeholder="---" value={values.upc} onBlur={onSubmit} onChange={(e) => onValueChange('upc', e.target.value)()} />
      </td>
    ),
    vendorSKU: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <input readOnly={locked} type="text" className="table-input" placeholder="---" value={values.vendorSKU} onBlur={onSubmit} onChange={(e) => onValueChange('vendorSKU', e.target.value)()} />
      </td>
    ),
    bundleQty: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <input type="text" className="table-input" readOnly={locked} value={values.bundleQty} onBlur={onSubmit} onChange={(e) => onValueChange('bundleQty', Number(e.target.value))()} placeholder="0" />
      </td>
    ),
    caseQty: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <input type="text" className="table-input" readOnly={locked} value={values.caseQty} onBlur={onSubmit} onChange={(e) => onValueChange('caseQty', Number(e.target.value))()} placeholder="0" />
      </td>
    ),
    prepCost: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{...getCommonPinningStyles(cell.column)}}>
        <div className='flex items-center gap-2'>
        <BadgeSelect
          selected={values.prepCost || ''}
          badges={prepCosts?.map((p: IPrepCost) => p.name) || []}
          onSelect={(prepCost) => {
            setValues((old) => ({ ...old, prepCost }))
            onSubmit(undefined, { prepCost })
          }}
          singleColor="blue"
          editable={!locked && !submitting}
        />
        <span>
        ${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.prepCost)?.amount, 2)}
        </span>
        </div>
      </td>
    ),
    buyer: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{...getCommonPinningStyles(cell.column)}}>
        <BadgeSelect
          selected={values.buyer || ''}
          badges={availableBuyers?.map((buyer: IBuyer) => buyer.name) || []}
          onSelect={(buyer) => {
            setValues((old) => ({ ...old, buyer }))
            onSubmit(undefined, { buyer })
          }}
          editable={!locked && !submitting}
        />
      </td>
    ),
    created_at: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{item.created_at ? new Date(item.created_at).toLocaleDateString('en-US') : '---'}</td>
    ),
  }

  return (
    <TR>
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Item
