import { ColumnDef } from "@tanstack/react-table";
import { IWarehouseInboundShipmentItemColumn } from "utils/warehouse";

export const columnDef: ColumnDef<IWarehouseInboundShipmentItemColumn>[] = [
    {
        id: "invoiceNumber",
        header: "Invoice Number",
        meta: {
            filters: "string",
        }
    }, 
    {
        id: "image",
        header: "Image",
        meta: {
            filters: "string",
        }
    }, 
    {
        id: "asin",
        header: "ASIN",
        meta: {
            filters: "string",
        }
    },
    {
        id: "title",
        header: "Title",
        meta: {
            filters: "string",
        }
    },
    {
        id: "upcOverSKU",
        header: "UPC / Vendor SKU",
        meta: {
            filters: "string",
        }
    },
    {
        id: "casePack",
        header: "Case Pack",
        meta: {
            filters: "number",
        }
    },
    {
        id: "caseOrder",
        header: "Case Order",
        meta: {
            filters: "number",
        }
    },
    {
        id: "arrivedCaseQty",
        header: "Arrived Cases",
        meta: {
            filters: "number",
        }
    },
    {
        id: "units",
        header: "Expected Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "arrivedUnits",
        header: "Arrived Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "difference",
        header: "Difference",
        meta: {
            filters: "number",
        }
    },
    {
        id: "missing",
        header: "Missing",
        meta: {
            filters: "number",
        }
    },
    {
        id: "damaged",
        header: "Damaged",
        meta: {
            filters: "number",
        }
    },
    {
        id: "bundle",
        header: "Bundle Quantity",
        meta: {
            filters: "number",
        }
    },
    {
        id: "sellable",
        header: "Sellable Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "prepCost",
        header: "Prep Type",
        meta: {
            filters: "string",
        }
    },
    {
        id: "expirationDate",
        header: "Expiration Date",
        meta: {
            filters: "date",
        }
    }
]