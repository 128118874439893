import { FC } from "react";
import FilteringProvider from "./FilteringProvider";

const withFiltering = <T = {}>(Component: FC<T>, name: string) => {
   const Wrapper = (props: T) => {
      return (
        <FilteringProvider name={name}>
          <Component {...props as any} />
        </FilteringProvider>
    )
   }

   Wrapper.displayName = `withFiltering(${Component.displayName})`
   return Wrapper
}

export default withFiltering
