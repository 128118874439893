import { CloseIcon } from 'assets/icons'
import Empty from 'components/Empty'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { ExcelUpload } from 'utils/excelUpload'
import {useDropzone} from 'react-dropzone'

interface R {
  result: any[] | File
}

type ExcelUploadType = typeof ExcelUpload['prototype']

interface P<T extends ExcelUploadType = ExcelUploadType> {
  respondWithFile?: boolean
  template: T
  additionalInfo?: string
}

const BulkUpload: AwaitableModal<R, P> = ({ open, onCancel, resolve, initialData: { template, respondWithFile, additionalInfo } }) => {
  const [result, setResult] = useState<any[]>()
  const [file, setFile] = useState<File>()

  const downloadTemplate = async (e: any) => {
    template.downloadTemplate()
  }

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (!file) return
    const fr = new FileReader()
        fr.onload = () => {
          try {
            const r = template.load(fr.result as ArrayBuffer, 'array', respondWithFile ? 5 : undefined)
            if (!r) throw new Error('Invalid file')
            setResult(r)
          } catch (err: any) {
            handleError(err)
          }
        }
        fr.readAsArrayBuffer(file as File)
        setFile(file as File)
  }

  const onBulkUpload = async (e: any) => {
    template
      .upload()
      .then((res) => {
        const fr = new FileReader()
        fr.onload = () => {
          try {
            const r = template.load(fr.result as ArrayBuffer, 'array', respondWithFile ? 5 : undefined)
            if (!r) throw new Error('Invalid file')
            setResult(r)
          } catch (err: any) {
            handleError(err)
          }
        }
        fr.readAsArrayBuffer(res as File)
        setFile(res as File)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onResolve = () => {
    resolve((!result || !file) ? undefined : { result: respondWithFile ? file : result })
  }
  
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})

  return (
    <Modal open={open} close={onCancel}>
      <div className="bg-white rounded-xl flex-col overflow-hidden divide-y divide-y-slate-200 relative">
        <header className="!mb-0 flex items-center justify-between gap-10">
          <div className="flex flex-col gap-1">
          <span>Bulk Upload</span>
          {!!additionalInfo && (<span className="text-base text-slate-500">🛈 {additionalInfo}</span>)}
          <span className="text-sm text-slate-500">Make Sure To Have The Data Formatted Correctly (i.e. remove currency symbols), Date Format Should be YYYY-MM-DD</span>
          </div>
          <CloseIcon onClick={onCancel} className="rounded-full hover:bg-slate-200 p-2 cursor-pointer w-10 h-10" />
        </header>
        {result ? (
          <>
      <input {...getInputProps()} className="hidden" />
            <div className="flex flex-col p-6 gap-4 overflow-auto"  {...getRootProps()}>
              <h3>Preview</h3>
              <table className="w-full border border-slate-200 rounded-2xl [&_td]:!p-3 [&_th]:!p-3 [&_th]:border">
                <thead>
                  <tr>
                    {Object.keys(result[0]).map((k) => (
                      <th key={k} className="text-left">{k}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {result.slice(0, 10).map((r: any) => (
                    <tr key={JSON.stringify(r)}>
                      {Object.values(r).map((v: any) => (
                        <td className="text-left select-text">{v ? v.toString() : "---"}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <footer className="flex items-center gap-10 p-6 justify-between">
              <button className="button-destructive" onClick={onCancel}>
                Cancel
              </button>
              <div className="flex gap-4 items-center">
                <button className="button-secondary" onClick={onBulkUpload}>
                  Change
                </button>
                <button className="button-primary" onClick={onResolve}>
                  Continue
                </button>
              </div>
            </footer>
          </>
        ) : (
          <>
            <div className="p-6" {...getRootProps()}>
              <Empty text="No file uploaded" />
            </div>
            <footer className="flex gap-10 items-center p-6 justify-between">
              <button className="button-destructive" onClick={onCancel}>
                Cancel
              </button>
              <div className="flex gap-4 items-center">
                <button className="button-secondary" onClick={downloadTemplate}>
                  Download Template
                </button>
                <button className="button-primary" onClick={onBulkUpload}>
                  Upload
                </button>
              </div>
            </footer>
          </>
        )}
      {
        isDragActive &&
        <div className='absolute inset-0 flex items-center justify-center backdrop-blur-xl pointer-events-none'>
          Drop here ...
        </div>
      }
      </div>
    </Modal>
  )
}

export default BulkUpload;
