import { useState, useEffect, PropsWithChildren, forwardRef, Dispatch, SetStateAction, useCallback } from 'react'
import { collection, CollectionReference, doc, DocumentReference, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import Context from './context'
import useAuthContext from '../Auth/useAuthContext'
import firebase from '../../config/firebase'
import { IUser } from 'types/user'
import { IPreference, IPreferences, IPreferenceType, ITablesPreferences } from 'types/preferences'
import FullLoader from 'components/loaders/FullLoader'

export interface IConnectedClient {
  id: string
  client: string
}

const refs = (user: string) => ({
  tables: doc(firebase.db, "users", user, "preferences", "tables") as DocumentReference<ITablesPreferences>
})

const query = (user: string) => collection(firebase.db, "users", user, "preferences") as CollectionReference<IPreference>

const PreferencesProvider = forwardRef<{ setUser: Dispatch<SetStateAction<IUser | null>> }, PropsWithChildren>(({ children }, forwarded) => {
  const auth = useAuthContext()
  const [preferences, setPreferences] = useState<IPreferences>()
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const user = auth?.uid
    if (!user) return
    return onSnapshot(query(user), (snapshot) => {
      setPreferences((old) => {
        const updated = {...(old || {})} as IPreferences;
        snapshot.docs.forEach((doc) => {
          updated[doc.id as IPreferenceType] = doc.data()
        })
        return updated
      })
    })
  }, [auth])

  const savePreference = useCallback(async <T extends IPreferenceType = IPreferenceType>(type: T, preference: IPreference<T>) => {
    const user = auth?.uid
    if (!user) return
    if (saving) return;
    setSaving(true)
    const ref = refs(user)[type]
    return setDoc(ref, preference, {mergeFields: Object.keys(preference)}).then(() => true).catch(() => false).finally(() => setSaving(false))
  }, [auth?.uid, saving])

  if (!preferences) return <FullLoader />

  return <Context.Provider value={{preferences, savePreference, saving}}>{children}</Context.Provider>
})

export default PreferencesProvider
