//import { PromoV2 } from 'assets/images/promo/v2'
// import FAQQuestion from 'components/FAQQuestion'
import { PromoV2 } from 'assets/images/public'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import SubscribeToNewsletterModal from './modals/SubscribeToNewsletter'
import Logo from 'components/layout/Logo'

const HomePageV2: FC = () => {
  const navigate = useNavigate()
  const [subscribeToNewsletter, SubscribeToNewsletter] = useAwaitableModal(SubscribeToNewsletterModal, {} as any)
  const onSubscribeToNewsletter = () => {
    subscribeToNewsletter()
  }

  return (
    <>
      <SubscribeToNewsletter />
      <div className="flex flex-col bg-slate-950">
        <div className="px-12 xl:px-24 pt-12 pb-0 flex flex-col gap-12 items-center relative overflow-visible">
          <h1 className="text-5xl xl:text-[4rem] text-white leading-[150%] w-full text-center font-medium">
            Third Party Profits Solution for Your
            <br />
            <span className="text-blue-600">Amazon Business</span>
          </h1>
          <a href="#" onClick={onSubscribeToNewsletter} className="py-6 px-12 w-max rounded-full uppercase leading-[100%] bg-blue-600 text-base text-white font-medium hover:bg-blue-500 transition-colors">
            Subscribe to our Newsletter
          </a>
          <div className="rounded-t-3xl bg-[rgba(255,255,255,0.2)] aspect-square xl:aspect-[2/1] w-full xl:w-auto xl:h-[32rem] overflow-hidden z-10 relative">
            <video
              className="w-full h-full object-cover"
              onClick={(e) => (e.currentTarget.paused ? e.currentTarget.play() : e.currentTarget.pause())}
              src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            />
          </div>
          <div className="w-[16rem] h-[16rem] rounded-full bg-blue-600 absolute left-0 bottom-0 blur-[16rem]" />
          <div className="w-[16rem] h-[16rem] rounded-full bg-blue-600 absolute right-0 bottom-[32rem] translate-y-1/2 blur-[16rem]" />
        </div>
      </div>
      <div className="bg-white flex flex-col p-12 xl:p-24 relative z-10 gap-12 xl:gap-24">
        <div className="absolute top-0 left-1/2 xl:left-8 -translate-y-1/2 xl:translate-x-0 -translate-x-1/2">
          <img src={PromoV2.laptop} alt="laptop" className="xl:h-[14.25rem]" />
          <div className="flex items-center bg-white rounded-full py-3 pr-5 pl-2 drop-shadow-xl absolute bottom-0 left-1/2 translate-y-1/2 -translate-x-1/2 w-max font-medium text-slate-950 text-sm uppercase">
            <Logo className="h-10 aspect-[16/9]" />
            Third Party Profits
          </div>
        </div>
        <div className="grid gap-8 xl:gap-16 grid-cols-1 xl:grid-cols-2 pt-40 xl:pt-20">
          <div className="flex flex-col gap-8 xl:gap-12">
            <div className="flex items-center gap-4">
              <PromoV2.check className="w-6 xl:w-8 aspect-square shrink-0" />
              <span className="text-sm xl:text-lg font-medium text-slate-950">Capture Highest Profiting Opportunities</span>
            </div>
            <div className="flex items-center gap-4">
              <PromoV2.check className="w-6 xl:w-8 aspect-square shrink-0" />
              <span className="text-sm xl:text-lg font-medium text-slate-950">Increase Cash Flow</span>
            </div>
            <div className="flex items-center gap-4">
              <PromoV2.check className="w-6 xl:w-8 aspect-square shrink-0" />
              <span className="text-sm xl:text-lg font-medium text-slate-950">Manage Supplier Accounts</span>
            </div>
          </div>
          <div className="flex flex-col gap-8 xl:gap-12">
            <div className="flex items-center gap-4">
              <PromoV2.check className="w-6 xl:w-8 aspect-square shrink-0" />
              <span className="text-sm xl:text-lg font-medium text-slate-950">Increase Order Value With Suppliers</span>
            </div>
            <div className="flex items-center gap-4">
              <PromoV2.check className="w-6 xl:w-8 aspect-square shrink-0" />
              <span className="text-sm xl:text-lg font-medium text-slate-950">Make Data Driven Decisions</span>
            </div>
            <div className="flex items-center gap-4">
              <PromoV2.check className="w-6 xl:w-8 aspect-square shrink-0" />
              <span className="text-sm xl:text-lg font-medium text-slate-950">Track and Improve Profits</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row-reverse gap-12 xl:gap-16 items-center w-full">
          <div className="flex flex-col w-full xl:max-w-[26rem] gap-4 xl:py-16 shrink-0">
            <h2 className="text-3xl xl:text-5xl font-semibold text-slate-950">
              Keep a Watch on
              <br /> <span className="text-blue-600">Your Expenses</span>
            </h2>
            <h3 className="text-base xl:text-lg font-normal text-slate-950">
              From payroll, to software to even your warehouse lease. We provide in our platform trackable expenses so you know where your money is going.
            </h3>
          </div>
          <div
            className="grow aspect-[768/399] w-full xl:w-auto"
            style={{
              backgroundImage: `url(${PromoV2.expenses})`,
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className="flex flex-col xl:flex-row gap-12 xl:gap-16 items-center w-full">
          <div className="flex flex-col w-full xl:max-w-[26rem] gap-4 xl:py-16 shrink-0">
            <h2 className="text-3xl xl:text-5xl font-semibold text-slate-950">
              Your Suppliers Are the
              <br /> <span className="text-blue-600">Backbone of Your Business</span>
            </h2>
            <h3 className="text-base xl:text-lg font-normal text-slate-950">Double down and increase what is profitable and cut what isn't.</h3>
          </div>
          <div
            className="grow aspect-[768/399] w-full xl:w-auto"
            style={{
              backgroundImage: `url(${PromoV2.suppliers})`,
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className="flex flex-col xl:flex-row-reverse gap-12 xl:gap-16 items-center w-full">
          <div className="flex flex-col w-full xl:max-w-[26rem] gap-4 xl:py-16 shrink-0">
            <h2 className="text-3xl xl:text-5xl font-semibold text-slate-950">
              Your Database is
              <br /> <span className="text-blue-600">Your Business</span>
            </h2>
            <h3 className="text-base xl:text-lg font-normal text-slate-950">Make profit first decisions with the resources you have available.</h3>
          </div>
          <div
            className="grow aspect-[768/399] w-full xl:w-auto"
            style={{
              backgroundImage: `url(${PromoV2.database})`,
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className="flex flex-col xl:flex-row p-2 xl:gap-8 rounded-3xl bg-blue-600">
          <img src={PromoV2.paul} alt="Paul" className="h-auto xl:h-[20.25rem] w-full aspect-square xl:aspect-[unset] xl:w-[20.25rem] object-cover rounded-2xl border border-blue-600" />
          <div className="grow flex flex-col gap-6 justify-center p-6 xl:p-0">
            <p className="font-normal text-sm xl:text-lg text-white leading-[200%]">
              “Amazon Sellers don't know how to improve their bottom line. By 23 years old I have sold over $15,000,000 dollars on amazon! The secret to my success is making data driven decisions with
              limited resources.”
            </p>
            <h5 className="font-semibold text-base xl:text-[2rem] text-white">Paul - 8 Figure Amazon Seller</h5>
          </div>
        </div>
      </div>
      <div className="p-24 flex flex-col gap-12 items-center">
        <img src={PromoV2.logo} alt="logo" className="w-[16rem] h-[9rem] object-cover" />
        <h2 className="text-3xl xl:text-5xl font-medium xl:font-semibold text-white w-full text-center leading-[160%]">
          See Why Our Customers Say Third Party Profits is <br />
          <span className="text-blue-600">Essential for Their Amazon Business</span>
        </h2>
        <a href="#" onClick={onSubscribeToNewsletter} className="py-6 px-12 w-max rounded-full uppercase leading-[100%] bg-blue-600 text-base text-white font-medium hover:bg-blue-500 transition-colors">
          Leverage Our Systems
        </a>
      </div>
    </>
  )
}

export default HomePageV2
