import { Row } from '@tanstack/react-table'
import Checkbox from 'components/Checkbox'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TR from 'components/tables/TR'
import useTableContext from 'contexts/Table/useTableContext'
import { FC } from 'react'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import { RowRenderer } from 'types/tables'
import { usNumber } from 'utils/formatting'

interface props {
  row: Row<MasterUPCCatalogItem>
}

const Item: FC<props> = ({ row }) => {
  const  { id, supplier, vendorSKU, upc, unitCost, caseCost, casePack, title, uom, size } = row.original
  const {select} = useTableContext()

  const isChecked = !!select?.selected.includes(id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<MasterUPCCatalogItem> = {
    selection: (cell) => (
      !!select && (
        <td key={cell.id} onClick={() => select?.onSelectClick(id)} style={{...getCommonPinningStyles(cell.column)}}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      )),
    supplier: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{supplier || '---'}</td>
    ),
    title: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{title || '---'}</td>
    ),
    size: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{size || '---'}</td>
    ),
    uom: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{uom || '---'}</td>
    ),
    upc: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{upc || '---'}</td>
    ),
    vendorSKU: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{vendorSKU || '---'}</td>
    ),
    unitCost: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>${usNumber(Number(unitCost))}</td>
    ),
    casePack: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{Number(casePack)}</td>
    ),
    caseCost: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>${usNumber(Number(caseCost))}</td>
    ),
    }

  return (
    <TR className="[&>td]:select-text h-0 relative">
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Item
