import { Table } from "@tanstack/react-table";
import useSelect from "hooks/useSelect";
import { createContext } from "react";

export interface TableContextType {
    table: Table<any>,
    select?: ReturnType<typeof useSelect>,
    extra: any,
    locked: boolean
}

const TableContext = createContext<TableContextType>({} as TableContextType)

export default TableContext