import { ColumnDef } from "@tanstack/react-table";
import { IBuyer } from "types/buyers";

const columnDef: ColumnDef<IBuyer>[] = [
    {
        id: "name",
        header: "Name",
    },
    {
        id: "actions",
        header: "Actions",
    }
]

export default columnDef;