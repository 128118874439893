import { ColumnDef } from "@tanstack/react-table";

const columnDef: ColumnDef<{expander: null, parameter: string}>[] = [
    {
        id: "expander",
        header: "",
        size: 32,
    },
    {
        id: "parameter",
        header: "Parameter",
        meta: {
            filters: "string",
        }
    },
]

export default columnDef;