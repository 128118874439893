import { createContext } from "react";
import { IPreference, IPreferences, IPreferenceType } from "types/preferences";

interface ContextType {
    preferences: IPreferences;
    savePreference: <T extends IPreferenceType = IPreferenceType>(type: T, preference: IPreference<T>) => Promise<boolean | undefined>
    saving: boolean
}

const Context = createContext<ContextType>({} as ContextType)

export default Context