import Table from 'components/tables/Table'
import { FC, useCallback, useMemo } from 'react'
import columnDef from './tableDef'
import Icon from 'assets/icons/iconset'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateFormModal from 'components/modals/CreateForm'
import { Row } from '@tanstack/react-table'
import useBuyers from 'hooks/useBuyers'
import BuyerItem from './components/BuyerItem'
import { IBuyer } from 'types/buyers'
import buyersApi from 'api/buyers'

const Buyers: FC = () => {
  const { buyers: unorderedBuyers, setBuyers } = useBuyers()

  const renderRow = useCallback(
    (row: Row<IBuyer>) => {
      return <BuyerItem key={row.original.id} row={row} setItems={setBuyers} />
    },
    [setBuyers]
  )

  const createBuyer = useCallback((cost: any) => {
    return buyersApi.createBuyer(cost.name)
  }, [])

  const formConfig = {
    onSubmit: createBuyer,
    config: {
      title: 'New Buyer',
      sections: [
        {
          title: '',
          fields: [
            {
              id: 'name',
              name: 'Title',
              type: 'text' as const,
              required: true,
            },
          ],
        },
      ],
    },
  }

  const [addBuyer, CreateBuyerModal] = useAwaitableModal(CreateFormModal, formConfig)

  const onAdd = () => {
    addBuyer(formConfig).then((entry) => {
      if (entry) {
        setBuyers((old) => old && [entry, ...old])
      }
    })
  }

  const buyers = useMemo(() => {
    return unorderedBuyers?.slice(0).sort((a, b) => (a.default ? -2 : a.name > b.name ? 1 : -1))
  }, [unorderedBuyers])

  return (
    <div className="flex flex-col h-full bg-surface-light w-full p-4 overflow-hidden gap-3">
      <CreateBuyerModal />
      <div className="flex w-full grow overflow-hidden rounded-lg bg-surface-primary border border-border-primary">
        <Table name="housed-buyers" columns={columnDef} renderRow={renderRow} items={buyers} loading={!buyers} />
      </div>
      <div className="w-full flex flex-col items-start">
          <button className="button-primary !pl-2 shrink-0" onClick={onAdd} disabled={false}>
            <Icon name="Plus" className="w-5 h-5" />
            <span>Create</span>
          </button>
      </div>
    </div>
  )
}

export default Buyers
