import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import catalogApi from 'api/catalog'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { CatalogDashboard, CatalogItem } from 'types/catalog'
import { IApiFilter } from 'types/tableFiltering'

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}, setDashboard: (dashboard: CatalogDashboard) => void) => {
  return catalogApi.getCatalog({page, ...queryKey[1]}).then((res) => {
    if (res.dashboard) setDashboard(res.dashboard)
    return res
  })
}

const syncStateQueryFn = async () => {
  return catalogApi.getSyncState().then((res) => (res.syncedAt ? new Date(res.syncedAt) : null))
}

const syncFn = async () => {
  return catalogApi.sync().then(() => new Date())
}

const useCatalogue = () => {
  const {data: syncState} = useQuery({
    queryKey: ['catalog-sync-state'],
    queryFn: syncStateQueryFn,
  })

  const queryClient = useQueryClient()
  const {data: synced, mutate: sync, isPending: syncing} = useMutation({
    mutationKey: ['catalog-synced'],
    mutationFn: syncFn,
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query) => {
          if (query.queryKey[0] === 'catalog') {
            return true
          } else if (query.queryKey[0] === "catalog-sync-state") {
            return true
          } else {
            return false
          }
        }
      })
    }
  })

  const {search, ordering, opFilters: filters} = useFilteringContext()

  const queryKey = useMemo(() => (
    [
    'catalog',
    {
      search,
      ordering,
      filters
    }
    ]
  ), [search, ordering, filters])

  const [dashboard, setDashboard] = useState<CatalogDashboard>()

  const {data, refetch, fetchNextPage, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery({
    queryKey: queryKey as [string, IApiFilter],
    queryFn: (params) => queryFn(params, setDashboard),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    enabled: !synced || syncing,
  })

  const items = useMemo(() => data ? [...data.pages.flatMap((p) => p.items)] : undefined, [data])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<CatalogItem[]>> = (action) => {
    qc.setQueryData(queryKey, (old: typeof data) => {
      if (!old) return old
      if (action instanceof Function){
        return {
          ...old,
          pages: old.pages.map((p) => ({
            ...p,
            items: action(p.items)
          }))
        }
      } else {
        return {
          ...old,
          pages: old.pages.map((p, i) => ({
            ...p,
            items: action[i]
          }))
        }
      }
    })
  }
    

  return {
    items,
    setItems,
    sync,
    syncState,
    syncing,
    loadingNextPage: isFetchingNextPage,
    loadNextPage: fetchNextPage,
    load: refetch,
    hasMore,
    dashboard,
    setDashboard,
  }
}

export default useCatalogue
