import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AsinEntryItem, IShippingCost } from 'types/housedAsins'

const availableColumns = {
  supplier: "Supplier",
  upc: "UPC",
  vendorSKU: "Vendor SKU",
  bundle: "Bundle Qty",
}

interface MSKUGenerationPreferenceColumn {
  key: string
  prefix: string
  suffix: string
}

const UpdatePreferencesModal: AwaitableModal<AsinEntryItem> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [shippingCost, setShippingCost] = useState<Record<keyof IShippingCost, any>>()
  const [columns, setColumns] = useState<MSKUGenerationPreferenceColumn[]>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!open) {
      setShippingCost(undefined)
      setColumns(undefined)
      return
    }
    housedAsinsApi.getPreferences().then((res) => {
      setShippingCost(res.shippingCost)
      setColumns(res.mskuGeneration.columns)
    })
  }, [open])

  const handleSubmit = async () => {
    if (submitting) return
    if (!shippingCost) return
    if (!columns) return
    setSubmitting(true)
    const shippingCostItem = {
      ...shippingCost,
      cost: parseFloat(shippingCost.cost),
    }
    housedAsinsApi
      .updatePreferences({ shippingCost: shippingCostItem, mskuGeneration: { columns } })
      .then(() => navigate(0))
      .finally(() => setSubmitting(false))
  }

  const options = Object.keys(availableColumns).filter((col) => !columns?.map((c) => c.key).includes(col))

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2 border-b">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Preferences</span>
              <span className="text-sm text-text-secondary">Add your preferences.</span>
            </div>
          </div>
          <div className={['flex flex-col bg-surface-primary px-6', !shippingCost && '[&_input]:animate-pulse [&_select]:animate-pulse pointer-events-none'].asClass}>
            <div className="flex flex-col gap-2">
              <p className="text-text-primary font-medium text-base pt-4">Shipping Cost</p>
              <div className="grid grid-cols-2 gap-3 w-full">
                <div className="flex flex-col gap-1 w-full">
                  <span className="text-xs text-text-secondary font-medium">Charge Type</span>
                  <select
                    className="border border-border-primary py-[0.475rem] px-2 rounded-lg outline-none"
                    value={shippingCost?.type}
                    onChange={(e) => setShippingCost((prev) => prev && { ...prev, type: e.target.value })}
                  >
                    <option value="per_item">Per Unit</option>
                    <option value="per_pound">Per Pound</option>
                  </select>
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <span className="text-xs text-text-secondary font-medium">Cost</span>
                  <div className="flex w-full rounded border border-border-primary">
                    <div className="border-r border-r-border-primary p-3 bg-surface-light">
                      <Icon name="Dollar" />
                    </div>
                    <input type="text" value={shippingCost?.cost} onChange={(e) => setShippingCost((prev) => prev && { ...prev, cost: e.target.value })} className="p-1 pl-3.5" placeholder="0.00" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-text-primary font-medium text-base pt-4">MSKU Generation Template</p>
              <div className="w-full overflow-x-auto">
                <div className="flex gap-2 w-full">
                    {columns?.map((col) => (
                      <div key={col.key} className="flex items-center gap-1 p-2 bg-surface-secondary rounded-md">
                        <input type="text" value={col.prefix} onChange={(e) => setColumns((prev) => prev && prev.map((c) => (c.key === col.key ? { ...c, prefix: e.target.value } : c)))} placeholder="Prefix" className="border border-border-primary py-1 w-12 px-2 rounded-lg outline-none text-xs" />
                        <span className="text-xs text-text-primary font-medium w-max">{availableColumns[col.key as keyof typeof availableColumns]}</span>
                        <input type="text" value={col.suffix} onChange={(e) => setColumns((prev) => prev && prev.map((c) => (c.key === col.key ? { ...c, suffix: e.target.value } : c)))} placeholder="Suffix" className="border border-border-primary py-1 w-12 px-2 rounded-lg outline-none text-xs" />
                        <button className="rounded-full bg-surface-light p-1" onClick={() => setColumns((prev) => prev?.filter((c) => c.key !== col.key))}>
                          <Icon name="X" className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    {options.length > 0 && (
                      <select
                        className="border border-border-primary py-[0.475rem] px-2 rounded-lg outline-none"
                        value=""
                        onChange={(e) => setColumns((prev) => prev && [...prev, { key: e.target.value, prefix: '', suffix: '' }])}
                      >
                        <option value="" disabled>
                          Add Column
                        </option>
                        {options.map((opt) => (
                          <option key={opt} value={opt}>
                            {availableColumns[opt as keyof typeof availableColumns]}
                          </option>
                        ))}
                      </select>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdatePreferencesModal
