import { LogoSrc } from "assets";
import { FC } from "react";

interface props {
    className?: string
}

const Logo: FC<props> = ({ className }) => {
  
    return (
      <div className={["flex justify-center items-center", className].join(" ")}>
        <img src={LogoSrc} alt="logo" className="w-full h-full aspect-[16/9] m-2 object-cover" />
      </div>
    )
}

export default Logo;
