import { ColumnDef } from "@tanstack/react-table";
import { CatalogItem } from "types/catalog";

const columnDef: ColumnDef<CatalogItem>[] = [
    {
        id: "image",
        header: "Image",
        size: 60,
    },
    {
        id: "asin",
        header: "ASIN",
        size: 130,
        meta: {
            filters: "string",
        }
    },
    {
        id: "url",
        header: "URL",
        size: 48
    },
    {
        id: "name",
        header: "Title",
        meta: {
            queryField: "item.name",
            filters: "string",
        }
    },
    {
        id: "sku",
        header: "MSKU",
        meta: {
            filters: "string",
        }
    },
    {
        id: "suppliers",
        header: "Suppliers",
        meta: {
            filters: "array",
        }
    },
    {
        id: "buyPrice",
        header: "Asin Cost",
        meta: {
            filters: "string",
        }
    },
    {
        id: "prepCosts",
        header: "Prep Types",
        meta: {
            filters: "array",
        }
    },
    {
        id: "stock",
        header: "Total Inventory",
        meta: {
            filters: "number",
        }
    },
    {
        id: "offerPrice",
        header: "Listing Price",
        meta: {
            filters: "number",
        }
    },
    {
        id: "totalRevenue",
        header: "Total Revenue",
        meta: {
            filters: "number",
        }
    },
    {
        id: "totalCOGs",
        header: "Total COGs",
        meta: {
            queryField: "totalStockValue",
            filters: "number",
        }
    },
    {
        id: "totalNetProceeds",
        header: "Total Net Proceeds",
        meta: {
            filters: "number",
        }
    },
    {
        id: "profit",
        header: "Profit per Sale",
        meta: {
            filters: "number",
        }
    },
    {
        id: "totalProfit",
        header: "Total Profits",
        meta: {
            filters: "number",
        }
    },
    {
        id: "profitShare",
        header: "% of Total Profits",
        meta: {
            filters: "number",
        }
    },
    {
        id: "listingCreatedAt",
        header: "Date Created",
        meta: {
            filters: "date",
        }
    },
    {
        id: "type",
        header: "Fulfillment Method",
        meta: {
            filters: "string",
        }
    },
    {
        id: "historicalDaysOfSupply",
        header: "Historical Days Of Supply",
        meta: {
            queryField: "historical_days_of_supply",
            filters: "number",
        }
    }
]

export default columnDef;