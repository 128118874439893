import { useState, FC, PropsWithChildren, useCallback, useEffect } from "react"
import useOperatorFilter, { OperatorFilter } from "hooks/useOperatorFilter"
import { IOrdering, IPeriodFilter } from "types/tableFiltering"
import FilteringContext from "./filteringContext"

interface Props {
  name: string
}

interface Storage {
  filter?: Record<string, OperatorFilter>;
  search?: string;
  ordering?: IOrdering
  period?: IPeriodFilter
}

const filterStorage = (name: string) => {
  const key = ['filters', name].join(".")
  return ({
    get: () => {
      const value = sessionStorage.getItem(key)
      return (value ? JSON.parse(value) : {}) as Storage
    },
    set: (value: Storage | null) => {
      if (!value) return sessionStorage.removeItem(key)
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  })
}

const FilteringProvider: FC<PropsWithChildren<Props>> = ({ children, name }) => {
  const [initial, setInitial] = useState(true)

  const {filter, setFilter} = useOperatorFilter()
  const [search, setSearch] = useState<string>('')
  const [searching, setSearching] = useState<boolean>(false)
  const [ordering, setOrdering] = useState<IOrdering | undefined>(undefined)
  const [period, setPeriod] = useState<IPeriodFilter>({since: undefined, until: undefined})

  const resetFilters = useCallback(() => {
    const storage = filterStorage(name).get()
    setFilter(storage.filter || {})
    setSearch(storage.search || '')
    setOrdering(storage.ordering || undefined)
    setSearching(false)
    setPeriod(storage.period || {since: undefined, until: undefined})
    setInitial(false)
  }, [name, setFilter])

  useEffect(() => {
    if (initial) return
    const value = {
      filter: filter || {},
      search: search || "",
      ordering: ordering,
      period,
    }
    const storage = filterStorage(name)
    storage.set(value)
  }, [name, initial, filter, search, ordering, period])

  useEffect(() => {
    resetFilters()
  }, [resetFilters])

  return (
    <FilteringContext.Provider value={{ search, setSearch, opFilters: filter, setOpFilters: setFilter, ordering, setOrdering, searching, setSearching, period, setPeriod}}>
      {children}
    </FilteringContext.Provider>
  )
}

export default FilteringProvider