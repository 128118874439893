import { useQuery, useQueryClient } from '@tanstack/react-query'
import warehouseApi from 'api/warehouse'
import ContentLayout from 'components/layout/Content'
import Table from 'components/tables/Table'
import useTitle from 'contexts/Title/useTitle'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { columnDef } from './tableDef'
import { IWarehouseInboundShipmentItem } from 'types/warehouse'
import { IWarehouseInboundShipmentItemColumn, getWarehouseInboundShipmentItemColumns } from 'utils/warehouse'
import Item from './components/Item'
import { frontendFilter } from 'hooks/frontendFilter'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import withFiltering from 'contexts/Filter/wrapper'
import Overview from './components/Overview'
import { Row } from '@tanstack/react-table'
import useSelect from 'hooks/useSelect'

const queryFn = async ({ queryKey }: { queryKey: string[] }) => {
  return warehouseApi.getWarehouseInboundShipment(queryKey[1])
}

const WarehouseInboundShipmentPage: FC = () => {
  const id = useParams<{ id: string }>().id as string
  const queryKey = useMemo(() => ['warehouse-inbound-shipment', id], [id])
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
  })

  const [markingArrived, setMarkingArrived] = useState(false)
  const navigate = useNavigate()
  const select = useSelect()

  const onMarkArrived = useCallback(() => {
    if (markingArrived) return
    if (!data) return
    setMarkingArrived(true)
    warehouseApi
      .arriveWarehouseInboundShipment(id)
      .then(() => {
        if (data.cursor?.next) {
          navigate('../' + data.cursor.next, { replace: true, relative: "path" })
        } else {
          navigate('../../inventory', { replace: true, relative: "path" })
        }
      })
      .finally(() => setMarkingArrived(false))
  }, [id, navigate, markingArrived, data])

  const filters = useFilteringContext()

  const items = useMemo(() => {
    if (!data) return
    const transformed = data.shipment.items.map((item) => getWarehouseInboundShipmentItemColumns(item, data.shipment.sourcePO?.invoiceNumber ?? ''))
    const filtered = frontendFilter(transformed, filters)
    return filtered.map((item) => ({ __raw: data.shipment.items.find((i) => i.id === item.id)!, ...item })) as ({ __raw: IWarehouseInboundShipmentItem } & IWarehouseInboundShipmentItemColumn)[]
  }, [data, filters])

  useTitle('__back__Inbound Receiving')

  const qc = useQueryClient()

  const onUpdate = useCallback(
    (entry: Partial<IWarehouseInboundShipmentItem>) => {
      qc.setQueryData(queryKey, (old: typeof data) => {
        if (!old) return old
        return {
          ...old,
          shipment: {
            ...old.shipment,
            items: old.shipment.items.map((item) => {
              if (item.id === entry.id) {
                return { ...item, ...entry }
              }
              return item
            }),
          },
        }
      })
    },
    [queryKey, qc]
  )

  const renderRow = useCallback(
    (row: Row<{ __raw: IWarehouseInboundShipmentItem } & IWarehouseInboundShipmentItemColumn>) => <Item key={row.original.id} raw={row.original.__raw} row={row as any} onUpdate={onUpdate} />,
    [onUpdate]
  )

  return (
    <ContentLayout
     wrapperClass="gap-4 !bg-surface-light !border-none !flex !flex-col !h-full w-full"
     buttons={
        <button
          className="button-primary"
          onClick={onMarkArrived}
          disabled={markingArrived}
        >
          Mark Arrived
        </button>
     }

     >
      <Overview shipment={data?.shipment} cursor={data?.cursor} />
      <div className="!overflow-y-auto !bg-surface-light !border-none !flex !flex-col h-full">
        <div className="flex flex-col w-full h-full bg-surface-primary !rounded-lg gap-4 [&>div:nth-child(2)]:border [&>div:nth-child(2)]:border-border-primary [&>div:nth-child(2)]:rounded-lg [&>div:nth-child(2)]:min-h-full">
          <Table name="warehouse-inbound-shipment" columns={columnDef}
            select={select} items={items} renderRow={renderRow} loading={isLoading && !data} />
        </div>
      </div>
    </ContentLayout>
  )
}

export default withFiltering(WarehouseInboundShipmentPage, "warehouse-in-shipment")
