import { AxiosInstance } from 'axios'
import axios from './axios'
import { CreateWorkingPOInput, IShippingCost } from 'types/housedAsins'
import { IPurchaseOrder, IPurchaseOrderItem, WorkingPurchaseOrderItem } from 'types/purchaseOrders'
import { IApiFilter } from 'types/tableFiltering'

const possibleStatuses = ['open', 'closed'] as const
const notStatuses = ['!open', '!closed'] as const

type StatusQuery = typeof possibleStatuses[number] | typeof notStatuses[number]

class PurchaseOrdersApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getWorkingPurchaseOrders = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    
    return this.axios.get(`/purchaseOrders/working?${sp.toString()}`).then((res) => ({ ...res.data, search } as { items: WorkingPurchaseOrderItem[]; search?: string, next: number | null }))
  }

  updateWorkingPurchaseOrder = async (id: string, input: Omit<CreateWorkingPOInput, "id">) => {
    return this.axios.patch("/purchaseOrders/working/"+id, input).then((res) => res.data.item as WorkingPurchaseOrderItem)
  }

  getWorkingPurchaseOrdersOverview = async ({filters}: IApiFilter) => {
    const sp = new URLSearchParams()
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get("/purchaseOrders/working/overview?"+sp.toString()).then((res) => res.data as Record<string, string>)
  }
  
  deleteWorkingPurchaseOrders = async (filters: IApiFilter &  {ids?: string[], except?: string[]}) => {
    return this.axios.post("/purchaseOrders/working/delete", filters)
  }
  
  refreshWorkingPOAsins = async () => {
    return this.axios.post("/purchaseOrders/working/refresh-asins")
  }
  
  _createPurchaseOrders = async ({ search, ordering, filters, ids, except, backOrder = false }: IApiFilter &  {ids?: string[], except?: string[], backOrder?: boolean}) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    
    return this.axios.post("/purchaseOrders?"+sp.toString(), {ids, except, backOrder})
  }

  createPurchaseOrders = async (data: IApiFilter &  {ids?: string[], except?: string[]}) => {
    return this._createPurchaseOrders({...data, backOrder: false})
  }

  createBackOrders = async (data: IApiFilter &  {ids?: string[], except?: string[]}) => {
    return this._createPurchaseOrders({...data, backOrder: true})
  }

  getPurchaseOrders = async ({ page = 1, status }: { page?: number; status: StatusQuery }) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    sp.append('status', status)
    return this.axios.get(`/purchaseOrders?${sp.toString()}`).then((res) => (res.data as { next: number | null; purchaseOrders: IPurchaseOrder[], shippingCostDef: IShippingCost }))
  }

  getPurchaseOrder = async (id: string) => {
    return this.axios.get(`/purchaseOrders/${id}`).then((res) => res.data as {
      purchaseOrder: IPurchaseOrder,
      shippingCostDef: IShippingCost,
    })
  }

  updatePurchaseOrderItem = async (id: string, data: Partial<IPurchaseOrderItem>) => {
    return this.axios.patch(`/purchaseOrders/${id}/items/${data.id}`, data).then((res) => res.data as IPurchaseOrderItem)
  }

  async addItemsToPurchaseOrder(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/items`, {ids, except})
  }

  async deletePurchaseOrderItems(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/items/delete`, {ids, except}).then((res) => {
      const remainingItems = res.data.remaining as number;
      if (!remainingItems) {
        const parts = window.location.pathname.split("/").slice(0, 4)
        window.location.href = parts.join("/")
        throw new Error()
      }
    })
  }

  async createBackOrder(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/backOrder`, {ids, except}).then((res) => {
      const remainingItems = res.data.remaining as number;
      if (!remainingItems) {
        window.location.href = '/app/purchase-orders/open'
        throw new Error()
      }
    })
  }

  async backToWorking(id: string, {ids, except}: {ids?: string[], except?: string[]}) {
    return axios.post(`/purchaseOrders/${id}/working`, {ids, except}).then((res) => {
      const remainingItems = res.data.remaining as number;
      if (!remainingItems) {
        window.location.href = '/app/purchase-orders/working'
        throw new Error()
      }
    })
  }

  async inboundPurchaseOrder(id: string, data: {ids?: string[], except?: string[], shippingCost: number, eta: string}) {
    return axios.post(`/purchaseOrders/${id}/inbound`, data).then((res) => res.data.shipment as string)
  }

  async updateInvoice(id: string, invoice?: string) {
    return axios.patch(`/purchaseOrders/${id}/invoice`, {invoice})
  }
}

const purchaseOrdersApi = new PurchaseOrdersApi()

export default purchaseOrdersApi
