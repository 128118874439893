import { ColumnDef } from "@tanstack/react-table";
import { IPrepCost } from "types/prepCosts";

const columnDef: ColumnDef<IPrepCost>[] = [
    {
        id: "name",
        header: "Title",
    },
    {
        id: "amount",
        header: "Cost",
    },
]

export default columnDef;