import { FC } from 'react'

interface props {
  month: string;
}

const MonthHeader: FC<props> = ({ month }) => (
    <tr className="bg-border-primary sticky top-[3.35rem] z-[10] [&>td]:bg-border-primary header">
      <td />
      <td className="capitalize">{month}</td>
      <td colSpan={5} />
    </tr>
)

export default MonthHeader
