import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'
import { AsinEntryItem } from 'types/housedAsins'

const AddHousedModal: AwaitableModal<AsinEntryItem> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({} as Record<"asin" | "unitCost" | "upc", string>)

  const handleSubmit = async () => {
    if (submitting) return
    setSubmitting(true)
    const item = {
      ...values,
      unitCost: Number(values.unitCost),
    }

    if (isNaN(item.unitCost)) {
      setSubmitting(false)
      return handleError('Please enter valid numbers for unit cost.')
    }

    housedAsinsApi
      .addHoused([{ ...item }])
      .then(() => {
        window.location.reload()
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Create Housed Asin</span>
              <span className="text-sm text-text-secondary">Add your product information.</span>
            </div>
          </div>
          <div className="flex flex-col bg-surface-primary px-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-1 w-full col-span-2">
                <span className="text-xs text-text-secondary font-medium">Asin</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.asin}
                  onChange={(e) => setValues((prev) => ({ ...prev, asin: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">UPC</span>
                <input type="text" className="border border-border-primary py-2.5 px-2 rounded-lg" value={values.upc} onChange={(e) => setValues((prev) => ({ ...prev, upc: e.target.value }))} />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Unit Cost</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.unitCost}
                  onChange={(e) => setValues((prev) => ({ ...prev, unitCost: e.target.value }))}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddHousedModal
