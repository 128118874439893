import ExcelUpload from "../base"

const schema = [
    {
        name: "SKU",
        type: "text"
    },
    {
        name: "Asin Cost (optional)",
        type: "number",
        default: 0,
    },
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
