import Icon from "assets/icons/iconset"
import { FC } from "react"

const colors = [
    "white",
    "green",
    "yellow",
    "red",
] as const

interface props {
    value: typeof colors[number]
    onChange: (value: typeof colors[number]) => void
    disabled?: boolean
}

const ColorGroupToggle: FC<props> = ({value, onChange, disabled}) => {
    const handleClick = () => {
        if (disabled) return
        const currentIndex = colors.indexOf(value)
        const nextIndex = (currentIndex + 1) % colors.length
        onChange(colors[nextIndex])
    }

    return (
        <div className="flex gap-1 button-secondary !py-1 !px-2 !w-max" style={{cursor: disabled ? "default" : "pointer"}} onClick={handleClick}>
            <Icon name="Flag" className={"w-4 h-4"} style={{color: value}} />
        </div>
    )
}

export default ColorGroupToggle