import housedAsinsApi from 'api/housedAsins'
import { CloseIcon } from 'assets/icons'
import Empty from 'components/Empty'
import Modal from 'components/Modal'
import IconButton from 'components/buttons/IconButton'
import FullLoader from 'components/loaders/FullLoader'
import AreYouSure from 'components/modals/AreYouSure'
import useAwaitableModal, { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'

const ListUploads: AwaitableModal<boolean> = ({ open, onCancel, resolve: onResolve }) => {
  const [uploadKeys, setUploadKeys] = useState<string[]>()
  const [deleted, setDeleted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})

  useEffect(() => {
    housedAsinsApi.getHousedUploads().then(setUploadKeys)
  }, [])

  const onDelete = (uploadKey: string) => {
    if (submitting) return
    setSubmitting(true)
    housedAsinsApi.deleteHousedBulk({uploadKey}).then(() => {
      setUploadKeys((c) => c?.filter((r) => r !== uploadKey))
      setDeleted(true)
      setSubmitting(false)
    })
  }

  const resolve = () => {
    onResolve(deleted)
  }

  return (
    <Modal open={open} close={resolve}>
      <AreYouSureModal />
      <div className="bg-white rounded-xl flex-col overflow-y-hidden flex divide-y divide-y-slate-200 relative max-h-[calc(100vh-64px)] h-full w-[32rem] max-w-full">
        <header className="!p-4 !mb-0 flex items-center justify-between gap-4">
          <span className="text-lg text-text-primary">Previous Uploads</span>
          <CloseIcon onClick={onCancel} className="rounded-full hover:bg-slate-200 p-1 cursor-pointer w-8 h-8" />
        </header>
        <div className="grow w-full overflow-y-auto">
          <div className="flex flex-col p-6 gap-4">
            {uploadKeys === undefined ? (
              <FullLoader />
            ) : !uploadKeys.length ? (
              <Empty text="No Uploads" />
            ) : (
              <table className="w-full h-full border border-slate-200 rounded-2xl [&_td]:!p-1 [&_th]:!p-1 [&_th]:border">
                <thead>
                  <tr>
                    <td>Uploaded At</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {uploadKeys.map((r: any) => {
                    const date = r
                    return (
                      <tr key={r}>
                        <td>{new Date(date).toLocaleString()}</td>
                        <td>
                          <div className="flex gap-2">
                            <IconButton name="trash" size={24} onClick={() => areYouSure().then(() => onDelete(r))} />
                            <IconButton name="export" className="rotate-45" onClick={() => housedAsinsApi.exportMasterCatalog({ catalog: r })} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <footer className="flex gap-10 items-center p-4 justify-between">
          <button className="button-secondary" onClick={onCancel}>
            Close
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default ListUploads
