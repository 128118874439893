import { ColumnDef } from "@tanstack/react-table";
import { IExpense } from "types/expenses";

const columnDef: ColumnDef<IExpense>[] = [
    {
        id: "dateOfExpense",
        header: "Date of Expense",
        meta: {
            filters: "date",
        }
    },
    {
        id: "title",
        header: "Title",
        meta: {
            filters: "string",
        }
    },
    {
        id: "category",
        header: "Category",
        meta: {
            filters: "string",
        }
    },
    {
        id: "amount",
        header: "Amount",
        meta: {
            filters: "number",
        }
    },
    {
        id: "frequency",
        header: "Frequency",
        meta: {
            filters: "string",
        }
    },
]

export default columnDef;