import { FirebaseOptions, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, initializeFirestore, persistentLocalCache } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'
import { isDev, isLocal } from 'config/environment'

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyB8R03m8Evn3c7oy4Ye0htWr-3AAtPvxrY",
  authDomain: "third-party-profits.firebaseapp.com",
  projectId: "third-party-profits",
  storageBucket: "third-party-profits.appspot.com",
  messagingSenderId: "88246241834",
  appId: "1:88246241834:web:602abf45a0167de3081153",
  measurementId: "G-7YTWCMW2VK"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
isLocal && isDev && connectAuthEmulator(auth, 'http://localhost:9099')
const db = initializeFirestore(app, {
  localCache: persistentLocalCache()
})
isLocal && isDev && connectFirestoreEmulator(db, 'localhost', 8080)
const storage = getStorage(app)
isLocal && isDev && connectStorageEmulator(storage, 'localhost', 9199)
const messaging = getMessaging(app)

const firebase = { app, auth, db, storage, messaging }

export default firebase
