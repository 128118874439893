import purchaseOrdersApi from 'api/purchaseOrders'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import useSelect from 'hooks/useSelect'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'

interface Values {
  shippingCost: number
  eta: string
  name: string
  invoiceNumber: string
}

const InboundModal: AwaitableModal<undefined, ReturnType<typeof useSelect> > = ({ open, resolve, initialData }) => {
  const filter = !initialData ? {} : initialData.allSelected ? {except: initialData.selected} : initialData.selected.length ? {ids: initialData.selected} : {}
  const {id} = useParams() as {id: string}
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({} as Record<keyof Values, string>)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (submitting) return
    let shippingCost = parseFloat(values.shippingCost)
    if (isNaN(shippingCost)) shippingCost = 0
    // transform date to iso string, using the date as the date and midnight at user's timezone as time
    const date = new Date(values.eta)
    date.setHours(0, 0, 0, 0)
    const eta = date.toISOString()
    setSubmitting(true)
    const item = {
      eta,
      shippingCost,
      name: values.name,
      invoiceNumber: values.invoiceNumber || null,
    }
    purchaseOrdersApi
      .inboundPurchaseOrder(id, { ...filter, ...item })
      .then((shipmentId) => navigate('/app/warehouse/inbound/'+shipmentId))
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Submit</span>
              <span className="text-sm text-text-secondary">Add your supplier information.</span>
            </div>
          </div>
          <div className="flex flex-col bg-surface-primary px-6 gap-4">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Purchase Order Name</span>
                <input
                  type="text"
                  placeholder="Purchase Order Name"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.name}
                  onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Expected Delivery Date</span>
                <input
                  type="date"
                  lang="en-US"
                  placeholder="Expected Delivery Date"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.eta}
                  min={new Date().toISOString()}
                  onChange={(e) => setValues((prev) => ({ ...prev, eta: e.target.value }))}
                />
              </div>
              {/*<div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Shipping Cost</span>
                <input
                  type="text"
                  placeholder="Shipping Cost"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.shippingCost}
                  onChange={(e) => setValues((prev) => ({ ...prev, shippingCost: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Invoice Number</span>
                <input
                  type="text"
                  placeholder="Invoice Number"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.invoiceNumber}
                  onChange={(e) => setValues((prev) => ({ ...prev, invoiceNumber: e.target.value }))}
                />
              </div>*/}
            </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InboundModal
