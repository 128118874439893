import { Row } from '@tanstack/react-table'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TR from 'components/tables/TR'
import { FC } from 'react'
import { RowRenderer } from 'types/tables'
import { IWarehouseOutboundShipment } from 'types/warehouse'
import { usNumber } from 'utils/formatting'

interface props {
  row: Row<IWarehouseOutboundShipment>
}

const Item: FC<props> = ({ row }) => {
  const item = row.original

  const cellRenderers: RowRenderer<IWarehouseOutboundShipment> = {
    dateIn: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {new Date(item.dateIn).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
      </td>
    ),
    shipmentId: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.shipmentId} readOnly />
      </td>
    ),
    asins: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.asins, 0)}
      </td>
    ),
    units: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.units, 0)}
      </td>
    ),
    shipmentStatus: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.shipmentStatus}
      </td>
    ),
    dateOut: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {new Date(item.dateOut).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
      </td>
    ),
  }
  return <TR>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default Item
