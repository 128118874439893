import { useQuery, useQueryClient } from "@tanstack/react-query"
import buyersApi from "api/buyers"
import { Dispatch, SetStateAction, useCallback } from "react"
import { IBuyer } from "types/buyers"

const queryFn = async () => {
    return buyersApi.getBuyers()
}

const useBuyers = () => {
    const {data: buyers} = useQuery({
        queryKey: ['housed-buyers'],
        queryFn,
    })

    const qc = useQueryClient();

    const setBuyers: Dispatch<SetStateAction<IBuyer[] | undefined>> = useCallback((updater) => {
        qc.setQueryData<IBuyer[] | undefined>(['housed-buyers'], updater)
    }, [qc])

    return {
        buyers,
        setBuyers,
    }
}

export default useBuyers