import { FC, useCallback, useState } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import Table from 'components/tables/Table'
import columnDef from './tableDef'
import useTitle from 'contexts/Title/useTitle'
import useBulkDelete from 'hooks/useBulkDelete'
import useSelect from 'hooks/useSelect'
import usePrepCosts from 'hooks/usePrepCosts'
// import LockButton from 'components/buttons/LockButton'
import useBulkAction from 'hooks/useBulkAction'
import useSupplierNames from 'hooks/useSupplierNames'
import withFiltering from 'contexts/Filter/wrapper'
import purchaseOrdersApi from 'api/purchaseOrders'
import useWorkingPOs from 'hooks/useWorkingPOs'
import { WorkingPurchaseOrderItem } from 'types/purchaseOrders'
import DashboardTile from './components/DashboardTile'
import { usNumber } from 'utils/formatting'
import Icon from 'assets/icons/iconset'
import AddAsin from './components/AddAsin'
import UpdateColumns from 'components/modals/UpdateColums'
import { Row } from '@tanstack/react-table'
import useBuyers from 'hooks/useBuyers'

const WorkingPurchaseOrders: FC = () => {
  const { items, setItems, overview, loadingNextPage, loadNextPage } = useWorkingPOs()
  const { buyers } = useBuyers()
  const { prepCosts } = usePrepCosts()
  const select = useSelect()
  // const [locked, setLocked] = useState(true)
  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, purchaseOrdersApi.deleteWorkingPurchaseOrders, { header: 'Delete Entries', description: 'Are you sure you want to delete these entries?' })
  const [onCreatePurchaseOrder, creatingPurchaseOrders] = useBulkAction(select, purchaseOrdersApi.createPurchaseOrders, '/app/purchase-orders/open')
  const [adding, setAdding] = useState(false)
  const [refreshingAsins, setRefreshingAsins] = useState(false)

  const { supplierNames } = useSupplierNames()

  const onUpdate = useCallback(
    (entry?: WorkingPurchaseOrderItem) => {
      if (!entry) return
      setItems((old) => old && old.map((s) => (s.id === entry.id ? { ...entry } : s)))
    },
    [setItems]
  )

  const onRefresh = useCallback(() => {
    if (refreshingAsins) return
    setRefreshingAsins(true)
    purchaseOrdersApi.refreshWorkingPOAsins()
      .then(() => window.location.reload())
      .finally(() => setRefreshingAsins(false))
  }, [refreshingAsins])

  useTitle('Working Purchase Orders')

  const renderRow = useCallback((row: Row<WorkingPurchaseOrderItem>) => <Item key={row.original.id} row={row} onDone={onUpdate} />, [onUpdate])

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = creatingPurchaseOrders || deleting || refreshingAsins

  return (
    <div className="w-full h-full flex flex-col overflow-hidden bg-surface-light">
      <AddAsin asins={[]} open={adding} setOpen={setAdding} />
      <div className="w-full overflow-x-auto overflow-y-hidden pb-4">
        <div className="w-full flex md:grid-cols-3 2xl:grid-cols-7 gap-4 px-4 py-4 grow overflow-x-auto min-w-min">
          <DashboardTile title="Profits" className="bg-emerald-500" value={overview ? `$${usNumber(Number(overview.totalGross))}` : '...'} />
          <DashboardTile title="AVG Profit Per Unit" className="bg-emerald-400" value={overview ? `$${usNumber(Number(overview.avgGross))}` : '...'} />
          <DashboardTile title="AVG Margin" className="bg-blue-500" value={overview ? `${usNumber(Number(overview.avgMargin))}%` : '...'} />
          <DashboardTile title="AVG ROI" className="bg-blue-400" value={overview ? `${usNumber(Number(overview.avgRoi))}%` : '...'} />
          <DashboardTile title="Sellable Units" className="bg-slate-500" value={overview ? `${usNumber(Number(overview.sellable), 0)}` : '...'} />
          <DashboardTile title="COGS" className="bg-rose-500" value={overview ? `$${usNumber(Number(overview.totalCogs))}` : '...'} />
          <DashboardTile title="Revenue" className="bg-orange-500" value={overview ? `$${usNumber(Number(overview.revenue))}` : '...'} />
        </div>
      </div>
      <ContentLayout
        afterSearch={
          <>
            {/*<LockButton locked={locked} toggle={setLocked} />*/}
            <button className="button-primary" onClick={() => setAdding(true)}>
              <Icon name="Plus" />
              Add New ASIN
            </button>
          </>
        }
        underSearch={
          canDoBulk && (
            <div className="flex gap-4 items-center">
              <button className="button-destructive" disabled={!canDoBulk || bulkInProgress} onClick={onDelete}>
                Delete Items
              </button>
            </div>
          )
        }
        buttons={
          <div className="flex gap-4 items-center">
            <button className="button-secondary" disabled={bulkInProgress} onClick={onRefresh}>
              Refresh Data
            </button>
            <button className="button-secondary" disabled={!canDoBulk || bulkInProgress} onClick={onCreatePurchaseOrder}>
              <Icon name="Check" />
              Submit
            </button>
            <UpdateColumns table="purchase-order" columnDef={columnDef} />
          </div>
        }
      >
        <AreYouSureModal />
        <Table
          name="purchase-order"
          columns={columnDef}
          initialPinState={{
            left: ["targetPrice", "image", "totalCogs"]
          }}
          items={items}
          renderRow={renderRow}
          loading={!items}
          loadingNext={loadingNextPage}
          onBottom={loadNextPage}
          select={select}
          locked={false}
          extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p) || [], availableBuyers: buyers }}
        />
      </ContentLayout>
    </div>
  )
}

export default withFiltering(WorkingPurchaseOrders, "working-po")
