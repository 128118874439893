import { FC, useCallback, useEffect, useRef } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import Loader from 'components/loaders/Loader'
import Table from 'components/tables/Table'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import useAwaitableModal from 'hooks/useAwaitableModal'
import ListCatalogs from 'pages/app/housedAsins/MasterUPCCatalog/components/ListCatalogs'
import columnDef from './tableDef'
import useTitle from 'contexts/Title/useTitle'
import useMasterCatalog from 'hooks/useMasterCatalog'
import withFiltering from 'contexts/Filter/wrapper'
import useSelect from 'hooks/useSelect'
import CreateHoused from './components/CreateHoused'
import { Row } from '@tanstack/react-table'

const MasterUPCCatalog: FC = () => {
  const { catalog, importing, loadCatalog, loadingNextPage, importExcel, exportCSV, loadCatalogNextPage, UploadModal } = useMasterCatalog()
  const [listPreviousUploads, ListCatalogsModal] = useAwaitableModal(ListCatalogs, {})
  const [createHoused, CreateHousedModal] = useAwaitableModal(CreateHoused, [])
  const uploaded = Boolean(catalog?.length)
  const select = useSelect()

  const anyTimeSelected = useRef<Record<string, MasterUPCCatalogItem>>({})

  useEffect(() => {
    anyTimeSelected.current = ({
      ...anyTimeSelected.current,
      ...Object.fromEntries(select.selected.map(id => [id, catalog?.find(i => i.id === id)!]))
    })
  }, [anyTimeSelected, select, catalog])

  const onCreateHoused = useCallback(() => {
    const items = select.selected.map((id) => anyTimeSelected.current[id])
    createHoused(items)
  }, [createHoused, select, anyTimeSelected])

  const renderRow = useCallback((row: Row<MasterUPCCatalogItem>) => <Item key={row.original.id} row={row} />, [])

  useTitle('Wholesale Supply Chain')

  return (
    <ContentLayout
      buttons={
        <div className="flex items-center gap-3">
          <button className="button-secondary" onClick={onCreateHoused}>
            Add to Housed
          </button>
          <button
            className="button-secondary"
            onClick={() =>
              listPreviousUploads().then((res) => {
                if (res) loadCatalog()
              })
            }
          >
            Previous Uploads
          </button>
          {!!uploaded && (
            <button className="button-secondary" onClick={exportCSV}>
              Export CSV
            </button>
          )}
          {!importing ? (
            <button className="button-secondary" onClick={importExcel}>
              Import CSV
            </button>
          ) : (
            <Loader size={24} />
          )}
        </div>
      }
    >
      <CreateHousedModal />
      <UploadModal />
      <ListCatalogsModal />
      <Table name="master-catalog" columns={columnDef} initialPinState={{ left: ["supplier", "title"] }} items={catalog} noSelectAll select={select} renderRow={renderRow} loading={!catalog} loadingNext={loadingNextPage} onBottom={loadCatalogNextPage} />
    </ContentLayout>
  )
}

export default withFiltering(MasterUPCCatalog, "master-catalog")
