import { ColumnDef } from '@tanstack/react-table'
import { AsinEntryItem } from 'types/housedAsins'

const columnDef: ColumnDef<AsinEntryItem>[] = [
  {
    id: 'amzLink',
    header: 'AMZ Link',
  },
  {
    id: 'supplier',
    header: 'Supplier',
  },
  {
    id: 'currentCost',
    header: 'Unit Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'upc',
    header: 'UPC',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'vendorSKU',
    header: 'Vendor SKU',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'bundleQty',
    header: 'Bundle Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'caseQty',
    header: 'Case Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'prepCost',
    header: 'Prep Type',
  },
  {
    id: 'buyer',
    header: 'Buyer',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'created_at',
    header: 'Added At',
    meta: {
      filters: 'date',
    },
  },
]

export default columnDef
