import { FC, useCallback, useState } from 'react'
import columnDef from './tableDef'
import ContentLayout from 'components/layout/Content'
import Table from 'components/tables/Table'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateFormModal from 'components/modals/CreateForm'
import suppliersApi from 'api/suppliers'
import LeadRow from './Lead'
import { SupplierLead } from 'types/supplierLeads'
import useSupplierLeads from 'contexts/SupplierLeads/useSupplierLeads'
import useSelect from 'hooks/useSelect'
import useBulkDelete from 'hooks/useBulkDelete'
import useExcelUpload from 'hooks/useExcelUpload'
import { SupplierLeadsExcelUpload } from 'utils/excelUpload/templates'
import BulkUpload from 'components/modals/BulkUpload'
import useUserContext from 'contexts/User/useUserContext'
import { ref, uploadBytes } from 'firebase/storage'
import firebase from 'config/firebase'
import { useNavigate } from 'react-router'
import Icon from 'assets/icons/iconset'
import useBulkAction from 'hooks/useBulkAction'
// import LockButton from 'components/buttons/LockButton'
import { Row } from '@tanstack/react-table'

const SupplierLeads: FC = () => {
  const user = useUserContext()
  const navigate = useNavigate()
  const { leads, setLeads, loading, loadNextPage, loadingNextPage } = useSupplierLeads()
  const select = useSelect()
  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, suppliersApi.deleteLeadsBulk, { header: 'Delete Leads', description: 'Are you sure you want to delete these leads?' })
  const excelUpload = useExcelUpload(SupplierLeadsExcelUpload)
  const [importing, setImporting] = useState<'uploading' | 'processing' | false>(false)
  const [requestUpload, UploadModal] = useAwaitableModal(BulkUpload, { template: excelUpload, respondWithFile: true })  
  const [onPushToSuppliers, pushingToSuppliers] = useBulkAction(select, suppliersApi.pushLeadsToSuppliers)
  // const [locked, setLocked] = useState(true)

  const importExcel = useCallback(async () => {
    if (importing) return
    setImporting('uploading')
    return requestUpload()
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dir = "supplierLeads"
        const dest = `users/${user?.id}/${dir}/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          suppliersApi.uploadSupplierLeadsBulk(dest).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, requestUpload, user?.id])

  const createLead = useCallback((supplier: any) => {
    return suppliersApi.createLead(supplier as any)
  }, [])

  const formConfig = {
    onSubmit: createLead,
    config: {
      title: 'New Lead',
      sections: [
        {
          title: 'Lead Details',
          fields: [
            { id: 'name', name: 'Name', type: 'text' as const, required: true },
            { id: "website", name: "Website", type: "text" as const, required: true },
          ],
        },
      ],
    },
  }

  const [addSupplier, AddSupplierComponent] = useAwaitableModal(CreateFormModal, formConfig)

  const onAdd = () => {
    addSupplier(formConfig).then((lead) => {
      if (lead) {
        setLeads((old) => old && [lead, ...old])
      }
    })
  }

  const onRemove = useCallback(
    (lead?: SupplierLead) => {
      if (!lead) return
      setLeads((old) => old && old.filter((s) => s.id !== lead.id))
    },
    [setLeads]
  )

  const onUpdate = useCallback(
    (lead?: SupplierLead) => {
      if (!lead) return
      setLeads((old) => old && old.map((s) => (s.id === lead.id ? lead : s)))
    },
    [setLeads]
  )

  const renderRow = useCallback((row: Row<SupplierLead>) => <LeadRow key={row.original.id} row={row} onUpdate={onUpdate} onRemove={() => onRemove(row.original)} />, [onRemove, onUpdate])

  const bulkInProgress = deleting || pushingToSuppliers
  const canDoBulk = select.selected.length > 0 || select.allSelected

  return (
    <ContentLayout
      // afterSearch={<LockButton locked={locked} toggle={setLocked} />}
      underSearch={
        canDoBulk ? (
          <div className="flex gap-4 items-center">
            <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
              Delete
            </button>
            <button className="button-primary w-max" onClick={onPushToSuppliers} disabled={bulkInProgress}>
              Push To Supplier Accounts
            </button>
          </div>
        ) : undefined
      }
      buttons={
        <div className="flex items-center gap-4">
          <button className="button-primary !pr-2" onClick={importExcel} disabled={!!importing}>
            <span>Import CSV</span>
            <Icon name="UploadSimple" className="w-5 h-5" />
          </button>
          <button className="button-secondary" onClick={onAdd}>
            New Lead
          </button>
        </div>
      }
    >
      <AreYouSureModal />
      <AddSupplierComponent />
      <UploadModal />
      <Table name="supplier-leads" columns={columnDef} initialPinState={{left: ["name", "website"]}} items={leads} locked={false} renderRow={renderRow} loading={loading} loadingNext={loadingNextPage} onBottom={loadNextPage} select={select} />
    </ContentLayout>
  )
}

export default SupplierLeads
