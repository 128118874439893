import { AxiosInstance } from 'axios'
import axios from './axios'
import { IApiFilter } from 'types/tableFiltering'
import { RestockingItem, SalesWeights } from 'types/restocking'

class RestockingApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getRestocking = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    if (search) sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/restocking?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; items: RestockingItem[]; search?: string; next: number | null }))
  }

  getWeights = async (sku: string) => {
    return this.axios.get("/restocking/weights", {params: {sku}})
      .then((res) => res.data as {
        weights: SalesWeights
        salesPerPeriod: SalesWeights
      })
  }

  updateWeights = async (weights: SalesWeights) => {
    return this.axios.put("/restocking/weights", { weights })
      .then((res) => res.data.weights as SalesWeights)
  }
}

const restockingApi = new RestockingApi()

export default restockingApi
