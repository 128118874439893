import { useQueryClient } from '@tanstack/react-query'
import restockingApi from 'api/restocking'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useCallback, useEffect, useState } from 'react'
import { SalesWeights } from 'types/restocking'
import { usNumber } from 'utils/formatting'

const validateWeights = (weights: SalesWeights) => {
  if (Object.values(weights).some((v) => isNaN(Number(v)))) {
    handleError('All weights must be numbers')
    return false
  }
  Object.entries(weights).forEach(([key, value]) => {
    if (+value < 0) {
      handleError(`Weight for period ${key} must be greater than 0`)
      return false
    }
  })
  return Object.fromEntries(Object.entries(weights).map(([key, value]) => [key, +value])) as unknown as SalesWeights
}

const VelocityWeightsModal: AwaitableModal<undefined, string | undefined> = ({ open, resolve, initialData: sku }) => {
  const [weights, setWeights] = useState<SalesWeights>()
  const [unitsSold, setUnitsSold] = useState<SalesWeights>()
  const [submitting, setSubmitting] = useState(false)

  const qc = useQueryClient()

  useEffect(() => {
    if (sku) {
      restockingApi.getWeights(sku).then(({weights, salesPerPeriod}) => {
        setWeights(weights)
        setUnitsSold(salesPerPeriod)
      })
    } else {
      setWeights(undefined)
      setUnitsSold(undefined)
    }
  }, [sku])

  const handleClose = useCallback(() => {
    if (submitting) return
    resolve()
  }, [submitting, resolve])

  const handleSubmit = useCallback(() => {
    if (!weights) return
    if (submitting) return
    const validWeights = validateWeights(weights)
    if (!validWeights) {
      return
    }
    setSubmitting(true)
    restockingApi.updateWeights(validWeights).then((weights) => {
      qc.invalidateQueries({
        predicate(query) {
          return query.queryKey.at(0) === 'restocking'
        },
      })
      resolve()
    }).finally(() => {
      setSubmitting(false)
    })
  }, [submitting, weights, resolve, qc])

  const weightsSum = weights ? Object.values(weights).reduce((acc, value) => acc + +value, 0) : 1

  const weightedVelocity = weights ? Object.entries(weights).reduce((acc, [key, value]) => {
    const period = +key.replace('period', '')
    const velocity = unitsSold?.[`period${period}` as keyof SalesWeights] ? unitsSold[`period${period}` as keyof SalesWeights] / period : 0
    return acc + velocity * +value
  }, 0) / weightsSum : null

  return (
    <Modal open={open} close={handleClose}>
      <div className="w-full rounded-xl max-w-[calc(100vw-4rem)] bg-surface-primary max-h-[calc(100vh-4rem)] h-max min-w-[16rem] overflow-y-auto">
        <div className="flex items-start justify-start p-6 pb-4 gap-2">
          <div className="flex flex-col items-start gap-4">
            <span className="text-base text-text-primary font-medium">Weighted Velocity</span>
            <div className="flex items-center gap-2">
              <Icon name="Info" className="w-5 h-5 text-text-secondary" />
              <span className="text-base text-text-primary font-medium">Adjusted sales velocity parameters</span>
            </div>
          </div>
        </div>
        <main className="overflow-x-auto grid w-full px-6 gap-3">
          <table className="w-full h-full rounded-lg overflow-hidden">
            <thead className="[&_th]:!text-xs [&_th]:!font-medium">
              <tr>
                <th>Period (days)</th>
                <th>Days In Stock</th>
                <th>
                  <div className="flex items-center gap-2">
                    <span>
                      Units Sold
                    </span>
                    <Icon name="Info" className="w-4 h-4" />
                  </div>
                </th>
                <th>Velocity</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody className="[&_td]:border-x-0 first:[&_td]:border-l last:[&_td]:border-r">
              {
                ([3, 7, 30, 60, 180, 365] as const).map((period) => (
                  <tr key={period}>
                    <td>{period}</td>
                    <td>{period}</td>
                    <td>{
                      unitsSold ? 
                    unitsSold[`period${period}`] : "..."
                    }</td>
                    <td>{
                      unitsSold ? 
                      usNumber(unitsSold[`period${period}`] ? unitsSold[`period${period}`] / period : 0)
                        : "..."
                    }</td>
                    <td>
                      <input className="table-input" value={weights?.[`period${period}`]} onChange={(e) => setWeights(old => old && { ...old, [`period${period}`]: e.target.value as unknown as number })} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="grid gap-2">
            <p className="text-text-secondary text-sm">
              Weighted velocity (units per day):<span className="ml-3 text-text-primary font-medium">{usNumber(weightedVelocity)}</span>
            </p>
            <p className="text-text-secondary text-sm">
              Out-of-stock day's are ignored when calculating the sales velocity
            </p>
          </div>
        </main>
        <div className="flex items-center gap-4 p-4 w-full">
          <button className="button-secondary grow" disabled={submitting} onClick={() => handleClose()}>
            Cancel
          </button>
          <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default VelocityWeightsModal
