import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import useBuyers from 'hooks/useBuyers'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { useState } from 'react'
import { AsinEntryItem, AsinEntryItemInput } from 'types/housedAsins'

const AddAsinEntryModal: AwaitableModal<AsinEntryItem> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({} as Record<keyof AsinEntryItemInput, string>)
  const { prepCosts } = usePrepCosts()
  const { buyers: availableBuyers } = useBuyers()
  
  const {supplierNames} = useSupplierNames();

  const handleSubmit = async () => {
    if (submitting) return
    setSubmitting(true)
    const item = {
      ...values,
      currentCost: Number(values.currentCost),
      bundleQty: parseInt(values.bundleQty),
      caseQty: parseInt(values.caseQty),
    }

    if (isNaN(item.currentCost) || isNaN(item.bundleQty) || isNaN(item.caseQty)) {
      setSubmitting(false)
      return handleError('Please enter valid numbers for cost, bundle quantity, and case quantity.')
    }

    housedAsinsApi
      .addAsinEntry({ ...item })
      .then((created) => {
        resolve(created)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Create ASIN Entry</span>
              <span className="text-sm text-text-secondary">Add your product information.</span>
            </div>
          </div>
          <div className="flex flex-col bg-surface-primary px-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-1 w-full col-span-2">
                <span className="text-xs text-text-secondary font-medium">Amazon Link</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.amzLink}
                  onChange={(e) => setValues((prev) => ({ ...prev, amzLink: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Supplier</span>
                <select
                  className={['border border-border-primary py-2.5 px-2 rounded-lg outline-none', !supplierNames && 'animate-pulse'].asClass}
                  value={values.supplier}
                  onChange={(e) => setValues((prev) => ({ ...prev, supplier: e.target.value }))}
                >
                  <option value="">Select Supplier</option>
                  {supplierNames?.map((supplier) => (
                    <option key={supplier} value={supplier}>
                      {supplier}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">UPC</span>
                <input type="text" className="border border-border-primary py-2.5 px-2 rounded-lg" value={values.upc} onChange={(e) => setValues((prev) => ({ ...prev, upc: e.target.value }))} />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Unit Cost</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.currentCost}
                  onChange={(e) => setValues((prev) => ({ ...prev, currentCost: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Bundle Quantity</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.bundleQty}
                  onChange={(e) => setValues((prev) => ({ ...prev, bundleQty: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Vendors SKU</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.vendorSKU}
                  onChange={(e) => setValues((prev) => ({ ...prev, vendorSKU: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Case Quantity</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.caseQty}
                  onChange={(e) => setValues((prev) => ({ ...prev, caseQty: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Prep Type</span>
                <select
                  className={['border border-border-primary py-2.5 px-2 rounded-lg outline-none', !prepCosts && 'animate-pulse'].asClass}
                  value={values.prepCost}
                  onChange={(e) => setValues((prev) => ({ ...prev, prepCost: e.target.value }))}
                >
                  <option value="">Select Prep Type</option>
                  {prepCosts?.map((p) => (
                    <option key={p.id} value={p.name}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Buyer</span>
                <input list="buyers" type="text" 
                    className={['border border-border-primary py-2.5 px-2 rounded-lg outline-none', !availableBuyers && 'animate-pulse'].asClass}
                    value={values.buyer}
                    onChange={(e) => setValues((prev) => ({ ...prev, buyer: e.target.value }))} />
                <datalist id="buyers">
                    {availableBuyers?.map((buyer) => (
                        <option key={buyer.id} value={buyer.name} />
                    ))}
                </datalist>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddAsinEntryModal
