import { Row } from '@tanstack/react-table'
import expensesApi from 'api/expenses'
import BadgeSelect from 'components/BadgeSelect'
import Checkbox from 'components/Checkbox'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TR from 'components/tables/TR'
import { expenseFrequencies } from 'constants/badgeSelect'
import useTableContext from 'contexts/Table/useTableContext'
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { IExpense } from 'types/expenses'
import { RowRenderer } from 'types/tables'

interface Props {
  row: Row<{type: "expense", expense: IExpense}>
  onUpdate: (expenses: IExpense[]) => void
}

const Expense: FC<Props> = ({ row, onUpdate }) => {
  const item = row.original.expense
  const [values, setValues] = useState(item)
  const [updating, setUpdating] = useState(false)
  const {select, extra} = useTableContext()

  const expenseCategories = extra.availableCategories || []

  useEffect(() => {
    setValues(item)
  }, [item])

  const update = (overrides?: Partial<IExpense>) => {
    if (updating) return
    setUpdating(true)
    expensesApi
      .updateExpense(item.id, { ...values, ...overrides })
      .then((created) => {
        setValues(created.expenses.find((e) => e.id === item.id) || values)
        onUpdate(created.expenses)
      })
      .finally(() => setUpdating(false))
  }

  const onValueChange = <T extends keyof IExpense = keyof IExpense>(key: T, value: IExpense[T]) => {
    return (submit = false) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({[key]: value})
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | undefined>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(undefined)
    updater(value)
  }

  const stopped = !!item.stoppedAt
  const replaced = !!item.replacedBy && !stopped
  const textColor = stopped ? "#F04438" : "#0F56CA"
  const backgroundColor = stopped ? "#FEF3F2" : "#EAF2FF"

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<{type: "expense", expense: IExpense}> = {
    selection: (cell) => (
      !!select && (
        <td key={cell.id} onClick={() => select?.onSelectClick(item.id)} style={{...getCommonPinningStyles(cell.column)}}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      )),
    dateOfExpense: (cell) => (
      <td key={cell.id} className="max-w-[20rem] truncate" style={{...getCommonPinningStyles(cell.column)}}>
        <input type="date" value={values.dateOfExpense} onChange={(e) => onValueChange('dateOfExpense', e.currentTarget.value)(true)} />
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} className="max-w-[20rem] truncate" style={{...getCommonPinningStyles(cell.column)}}>
        <div className='flex items-center gap-2'>
          <input type="text" value={values.title} onBlur={() => update()} onChange={(e) => onValueChange('title', e.currentTarget.title)} />
        {(stopped || replaced) && (<span
          style={{ backgroundColor, color: textColor }}
          className="px-2 py-1 rounded gap-1 text-xs font-medium flex items-center transition-colors cursor-default"
        >
          {stopped ? 'Stopped' : replaced ? 'Replaced' : ''}
        </span>)}
        </div>
      </td>
    ),
    category: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{...getCommonPinningStyles(cell.column)}}>
        <BadgeSelect badges={expenseCategories} selected={values.category} onSelect={(id) => onValueChange('category', id)(true)} editable />
      </td>
    ),
    amount: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <div className="relative">
          <span>$</span>
          <input type="number" className="pl-1" value={values.amount} onChange={onNumberChange((val) => onValueChange('amount', val as number)())} />
        </div>
      </td>
    ),
    frequency: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{...getCommonPinningStyles(cell.column)}}>
        <BadgeSelect badges={expenseFrequencies} selected={values.frequency} onSelect={(id) => onValueChange('frequency', id as any)(true)} editable />
      </td>
    ),
  }

  return (
    <TR className={[updating && 'grayscale'].asClass} onBlur={() => update()}>
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Expense
