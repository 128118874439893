import { ColumnDef } from '@tanstack/react-table'
import { HousedAsin } from 'types/housedAsins'

const columnDef: ColumnDef<HousedAsin & { image: string }>[] = [
  {
    id: 'image',
    header: 'Image',
    size: 60,
  },
  {
    id: 'asin',
    header: 'ASIN',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'supplier',
    header: 'Supplier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'title',
    header: 'Title',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'asinCost',
    header: 'ASIN Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'totalGross',
    header: 'Total Profits',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'margin',
    header: 'Margin',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'roi',
    header: 'ROI',
    meta: {
      filters: 'number',
    },
  },
]

export default columnDef
