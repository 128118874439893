import { SetStateAction, useCallback, useEffect, useState } from "react";

interface Config {
    sidebarCollapsed: boolean;
}

const configKey = "thirdpartyprofits.config";

const useLocalConfig = () => {
    const [config, setConfig] = useState<Partial<Config>>({});

    useEffect(() => {
        const localConfig = localStorage.getItem(configKey);
        if (localConfig) {
            setConfig(JSON.parse(localConfig));
        }

        const handleStorage = () => {
            const localConfig = localStorage.getItem(configKey);
            if (localConfig) {
                setConfig(JSON.parse(localConfig));
            }
        };

        window.addEventListener("storage", handleStorage);

        return () => {
            window.removeEventListener("storage", handleStorage);
        };
    }, [])

    const setLocalConfig = useCallback((action: SetStateAction<Partial<Config>>) => {
        setConfig((prevConfig) => {
            const newConfig = typeof action === "function" ? action(prevConfig) : action;
            localStorage.setItem(configKey, JSON.stringify(newConfig));

            const event = new StorageEvent("storage", {
                key: configKey,
                newValue: JSON.stringify(newConfig),
            });

            window.dispatchEvent(event);

            return newConfig;
        });
    }, []);

    return { config, setLocalConfig };
}

export default useLocalConfig;