import { Row } from '@tanstack/react-table'
import Icon from 'assets/icons/iconset'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TableSpanner from 'components/TableSpanner'
import useTableContext from 'contexts/Table/useTableContext'
import { FC, useCallback, useMemo, useState } from 'react'
import { IPnLTableRow } from 'types/pnl'

export const PNLTopLevelColors = {
  orange: '#F9CB9C',
  red: '#EA9999',
  teal: '#A2C4C9',
  blue: '#A4C2F4',
  green: '#B6D7A8',
}

interface props {
  row: Row<IPnLTableRow>
  index: number
  total: number
}

const currencyValue = (value = 0) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

const numberValue = (value = 0) => {
  return value.toLocaleString('en-US')
}

const percentValue = (value = 0) => {
  return value.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })
}

const Item: FC<props> = ({ row, index, total }) => {
  const item = row.original
  const [expanded, setExpanded] = useState(false)

  const topLevelValues = item.data.map((d) => d?.value)
  const breakdownValues = item.data.map((d) => d?.breakdowns?.map((b) => b.value))

  const valueFormatter = useCallback(
    (value: number, typeOverride?: typeof item.type) => {
      switch (typeOverride || item.type) {
        case 'currency':
          return currencyValue(value)
        case 'number':
          return numberValue(value)
        case 'percentage':
          return percentValue(value)
        default:
          return value
      }
    },
    [item]
  )

  const bgColor = useMemo(() => PNLTopLevelColors[item.color], [item.color])

  return (
    <>
      <tr className={['h-0 top-level', index % 2 === 0 ? '[&>td]:bg-[#D0E0FB]' : '[&>td]:bg-[#FFF]'].asClass}>
        {row.getVisibleCells().map((cell, i) =>
          i === 0 ? (
            <td className="w-0" style={{...getCommonPinningStyles(cell.column)}} /*style={{backgroundColor: bgColor}}*/ onClick={() => setExpanded(!expanded)}>
              {item.data?.[0]?.breakdowns?.length && (
                <div className="w-4 h-4 flex items-center justify-center cursor-pointer p-0.5 rounded-full hover:bg-[#00000020] transition-colors">
                  <Icon name={expanded ? 'CaretDown' : 'CaretRight'} />
                </div>
              )}
            </td>
          ) : i === 1 ? (
            <td className="max-w-[20rem] truncate font-bold" style={{...getCommonPinningStyles(cell.column)}} /*style={{backgroundColor: bgColor}}*/>
              {item.parameter}
            </td>
          ) : (
            <td key={cell.id} className="text-right" style={{...getCommonPinningStyles(cell.column)}} /*style={{backgroundColor: bgColor}}*/>
              {valueFormatter(topLevelValues[i - 2])}
            </td>
          )
        )}
      </tr>
      {(expanded || !item.data?.[0]?.breakdowns?.length) &&
        item?.otherTopLevelValues?.map((b, i) => (
          <tr key={b.parameter + i} className={['h-0', index % 2 === 0 ? '[&>td]:bg-[#D0E0FB]' : '[&>td]:bg-[#FFF]'].asClass}>
            {row.getVisibleCells().map((cell, j) =>
              j === 0 ? (
                <td key={cell.id} className="w-0 font-semibold" style={{...getCommonPinningStyles(cell.column)}} /*style={{backgroundColor: bgColor}}*/></td>
              ) : j === 1 ? (
                <td key={cell.id} className="max-w-[20rem] truncate font-semibold" style={{...getCommonPinningStyles(cell.column)}} /*style={{backgroundColor: bgColor}}*/>
                  {b.parameter}
                </td>
              ) : (
                <td key={cell.id} className="text-right" style={{...getCommonPinningStyles(cell.column)}} /*style={{backgroundColor: bgColor}}*/>
                  {valueFormatter(b.value[j - 2], b.type)}
                </td>
              )
            )}
          </tr>
        ))}
      {expanded &&
        item.data[0]?.breakdowns?.map((b, i) => (
          <tr key={b.parameter + i} className={['h-0', i % 2 === 0 ? '[&>td]:bg-white' : '[&>td]:bg-blue-50'].asClass}>
            {row.getVisibleCells().map((cell, j) =>
              j === 0 ? (
                <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}} className="w-0"></td>
              ) : j === 1 ? (
                <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}} className="max-w-[20rem] truncate">
                  {b.parameter}
                </td>
              ) : (
                <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}} className="text-right">
                  {valueFormatter(breakdownValues[j - 2]?.[i] || 0, b.type)}
                </td>
              )
            )}
          </tr>
        ))}
      {index !== total - 1 && false && (
        <TableSpanner rows={1} />
      )}
    </>
  )
}

export default Item
