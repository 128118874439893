import { FC, useCallback } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Table from 'components/tables/Table'
import withFiltering from 'contexts/Filter/wrapper'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { Row } from '@tanstack/react-table'
import useRestocking from 'hooks/useRestocking'
import { RestockingItem } from 'types/restocking'
import Loader from 'components/loaders/Loader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import VelocityWeightsModal from './components/VelocityWeights'

const RestockingPage: FC = () => {
  const { syncState, items, loadNextPage, loadingNextPage } = useRestocking()
  const { prepCosts } = usePrepCosts()
  const [updateVelocities, VelocityWeightsComponent] = useAwaitableModal(VelocityWeightsModal, undefined)

  const { supplierNames } = useSupplierNames()

  const renderRow = useCallback((row: Row<RestockingItem>) => <Item key={row.id} row={row} />, [])

  return (
    <div className="w-full h-full flex flex-col overflow-hidden bg-surface-light relative">
      {!syncState.synced && (
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[1000] flex items-center justify-center">
        <div className="bg-white rounded-lg flex flex-col items-center justify-center p-4">
          <Loader />
          <div className="text-center mt-3 font-medium">Syncing Sales Data... {syncState.syncedUntil ? `Synced until: ${new Date(syncState.syncedUntil).toDateString()}` : ""}</div>
          <div className="text-center mt-1 text-slate-500">This is a long running operation, don't close this page</div>
        </div>
      </div>  
      )}
      <VelocityWeightsComponent />
       <div>
        
       </div>
      <ContentLayout
        wrapperClass="relative"
        buttons={
          <div className="flex items-center justify-end gap-4 flex-wrap">
            {syncState !== undefined && (
              <div className="flex items-center gap-3">
                <span className="text-sm font-semibold text-base-500">Last Synced At: {syncState.syncedUntil ? new Date(syncState.syncedUntil).toDateString() : !syncState.syncedUntil ? "Never" : "..."}</span>
              </div>
            )}
          </div>
        }
      >
        <Table
          name="restocking"
          columns={columnDef}
          initialPinState={{
            left: ['image', 'asin', 'url', 'title'],
          }}
          items={items}
          locked={false}
          renderRow={renderRow}
          loading={!items || syncState === undefined}
          loadingNext={loadingNextPage}
          onBottom={loadNextPage}
          extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p.name), updateVelocities }}
        />
      </ContentLayout>
    </div>
  )
}

export default withFiltering(RestockingPage, 'restocking-page')
