import { ColumnDef } from "@tanstack/react-table";
import { IWarehouseInventoryItem } from "types/warehouse";

export const columnDef: ColumnDef<IWarehouseInventoryItem & {image: string}>[] = [
    {
        id: "image",
        header: "Image",
    },
    {
        id: "asin",
        header: "ASIN",
        meta: {
            filters: "string",
        }
    },
    {
        id: "title",
        header: "Title",
        meta: {
            filters: "string",
        }
    },
    {
        id: "upc",
        header: "UPC",
        meta: {
            filters: "string",
        }
    },
    {
        id: "units",
        header: "Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "bundle",
        header: "Bundle Quantity",
        meta: {
            filters: "number",
        }
    },
    {
        id: "sellable",
        header: "Total Sellable Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "prepCost_name",
        header: "Prep Type",
        meta: {
            filters: "string",
        }
    },
    {
        id: "expirationDate",
        header: "Expiration Date",
        meta: {
            filters: "date",
        }
    }
];