import Icon from 'assets/icons/iconset'
import Dropdown from 'components/Dropdown'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { FC, ReactNode, useEffect, useState } from 'react'

interface props {
  field: string
  title: string
  anchor: ReactNode
  dbType: 'string' | 'number' | 'date' | 'array'
}

const ColFilterSelect: FC<props> = ({ dbType, anchor, field, title }) => {
  const { opFilters, setOpFilters } = useFilteringContext()
  const [op, setOP] = useState<string | undefined>(opFilters?.[field]?.o)
  const [val, setVal] = useState<string | number | undefined>(opFilters?.[field]?.v as string | number | undefined)

  const optionsByDBType = {
    string: ['=', 'ILIKE', 'NOT ILIKE', 'IS NULL', 'IS NOT NULL', '<', '>', '<=', '>=', '!='],
    number: ['=', '<', '>', '<=', '>=', '!=', 'IS NULL', 'IS NOT NULL'],
    date: ['=', '<', '>', '<=', '>=', '!=', 'IS NULL', 'IS NOT NULL'],
    array: ['IN', 'NOT IN', 'IS NULL', 'IS NOT NULL'],
    boolean: ['IS TRUE', 'IS FALSE'],
  }
  const optionNames = {
    '=': 'Equals',
    ILIKE: 'Includes',
    'NOT ILIKE': 'Does Not Include',
    '<': 'Less Than',
    '>': 'Greater Than',
    '<=': 'Less Than or Equal',
    '>=': 'Greater Than or Equal',
    '!=': 'Not Equal',
    'IS NULL': 'Is Empty',
    'IS NOT NULL': 'Is Not Empty',
    IN: 'Includes',
    'NOT IN': 'Does Not Include',
    'IS TRUE': 'Is True',
    'IS FALSE': 'Is False',
  }
  const options = optionsByDBType?.[dbType]

  useEffect(() => {
    setOP(opFilters?.[field]?.o)
    setVal(opFilters?.[field]?.v as string | number | undefined)
  }, [opFilters, field])

  const onClear = () => {
    setOP(undefined)
    setVal(undefined)
  }

  const onApply = () => {
    const value = dbType === 'number' ? (isNaN(Number(val)) ? undefined : Number(val)) : val
    if (op === 'IS NULL' || op === 'IS NOT NULL' || op === 'IS TRUE' || op === 'IS FALSE') {
      setOpFilters((old) => ({ ...old, [field]: { o: op as any, v: null as any } }))
    } else if (!op || !value) {
      setOpFilters(({ [field]: _, ...old }) => ({ ...old }))
    } else {
      setOpFilters((old) => ({ ...old, [field]: { o: op as any, v: value } }))
    }
    close()
  }

  const close = () => {
    const event = new CustomEvent('hideSelector')
    document.dispatchEvent(event)
  }

  return (
    <Dropdown anchorEl={anchor} wrapperClass="-translate-x-[calc(100%-1.5rem)] translate-y-2 p-2">
      <div className="flex flex-col bg-surface-primary rounded-lg p-2 overflow-hidden gap-2 h-full" onClick={(e) => e.stopPropagation()}>
        <div className="flex items-center gap-2">
          <span className="text-text-primary text-sm grow">{title}</span>
          <button className="p-0.5 rounded-lg hover:bg-surface-secondary transition-colors hover:text-text-primary" onClick={close}>
            <Icon name="X" />
          </button>
        </div>
        <div className="border-y border-y-border-secondary grow overflow-hidden">
          {!op && (
            <div className="flex flex-col gap-2 text-left overflow-hidden h-full">
              <span className="text-text-secondary text-xs">Operator</span>
              <div className="overflow-y-auto">
                {options?.map((o) => (
                  <button
                    key={o}
                    className="button-tertiary w-full"
                    onClick={() => {
                      setOP(o)
                      setVal(undefined)
                    }}
                  >
                    {optionNames?.[o as keyof typeof optionNames]}
                  </button>
                ))}
              </div>
            </div>
          )}
          {op && <span className="text-text-primary lowercase grow">{optionNames?.[op as keyof typeof optionNames]}</span>}
          {op && !["IS NULL", "IS NOT NULL", "IS FALSE", "IS TRUE"].includes(op) && (
            <div className="input-box mt-2">
              <input type={dbType === 'date' ? 'date' : 'text'} value={val} className="!border-border-primary" onChange={(e) => setVal(e.currentTarget.value)} />
              <label>Value</label>
            </div>
          )}
        </div>
        <div className="flex gap-1">
          <button className="button-tertiary grow" onClick={onClear}>
            Clear
          </button>
          <button className="button-primary grow" onClick={onApply}>
            Apply
          </button>
        </div>
      </div>
    </Dropdown>
  )
}

export default ColFilterSelect
