import { ColumnDef } from "@tanstack/react-table";
import { IWarehouseOutboundShipment } from "types/warehouse";

export const columnDef: ColumnDef<IWarehouseOutboundShipment>[] = [
    {
        id: "dateIn",
        header: "Date In",
        meta: {
            filters: "date",
        }
    },
    {
        id: "shipmentId",
        header: "Shipment ID",
        meta: {
            queryField: "referenceId",
            filters: "string",
        }
    },
    {
        id: "asins",
        header: "Unique ASINs",
        meta: {
            filters: "number",
        }
    },
    {
        id: "units",
        header: "Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "shipmentStatus",
        header: "Status",
        meta: {
            filters: "string",
        }
    },
    {
        id: "dateOut",
        header: "Last Item Processed At",
        meta: {
            filters: "date",
        }
    },
];