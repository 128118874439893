import { IShippingCost } from "types/housedAsins";
import { IPrepCost } from "types/prepCosts";
import { IPurchaseOrder, IPurchaseOrderItem } from "types/purchaseOrders";
import XLSX from "xlsx-js-style";

const getSellableQty = (item: IPurchaseOrderItem) => {
    const buyQty = item.buyQty;
    const bundle = item.bundle;
    return buyQty / bundle;
}

const getEstimatedRevenue = (item: IPurchaseOrderItem) => {
    const price = parseFloat(item.targetSoldPrice || item.housedAsin.price);
    const sellable = getSellableQty(item);
    return price * sellable;
}

const getCostOfGoods = (item: IPurchaseOrderItem) => {
    const unitCost = parseFloat(item.targetPrice || item.unitCost || item.housedAsin.unitCost);
    const buyQty = item.buyQty;
    return unitCost * buyQty;
}

const getWeight = (item: IPurchaseOrderItem) => {
    const weight = parseFloat(item.housedAsin.weightValue);
    const buyQty = item.buyQty;
    return weight * buyQty;
}

const getFees = (item: IPurchaseOrderItem) => {
    return getSellableQty(item) * +item.housedAsin.fees;
}

const getShippingCost = (item: IPurchaseOrderItem, shippingCost?: IShippingCost) => {
    switch (shippingCost?.type) {
        case "per_pound":
            return getWeight(item) * shippingCost.cost;
        case "per_item":
            return item.buyQty * shippingCost.cost;
        default:
            return 0;
    }
}

const getPrepCost = (item: IPurchaseOrderItem, prepCosts?: IPrepCost[]) => {
    const prepCost = prepCosts?.find(cost => cost.name === item.housedAsin.prepCost);
    return (prepCost?.amount || 0) * getSellableQty(item);
}

const getCases = (item: IPurchaseOrderItem) => {
    return item.buyQty / item.casePack;
}

export type IPurchaseOrderOverview = ReturnType<typeof getPurchaseOrderOverview>;

export const getPurchaseOrderOverview = ({ purchaseOrder, shippingCostDef, prepCosts}: { purchaseOrder: IPurchaseOrder, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[] }) => {
    const totalEstimatedRevenue = purchaseOrder.items.reduce((acc, item) => acc + getEstimatedRevenue(item), 0);

    const totalCOGs = purchaseOrder.items.reduce((acc, item) => acc + getCostOfGoods(item), 0);
    const totalWeight = purchaseOrder.items.reduce((acc, item) => acc + getWeight(item), 0);
    const totalShipping = Number(purchaseOrder.shippingCost) || purchaseOrder.items.reduce((acc, item) => acc + getShippingCost(item, shippingCostDef), 0);
    const totalProduction = purchaseOrder.items.reduce((acc, item) => acc + getPrepCost(item, prepCosts), 0);

    const totalFees = purchaseOrder.items.reduce((acc, item) => acc + getFees(item), 0);
    const totalExpenses = totalShipping + totalProduction + totalCOGs;
    const totalCases = purchaseOrder.items.reduce((acc, item) => acc + getCases(item), 0);
    const totalUnitsPurchased = purchaseOrder.items.reduce((acc, item) => acc + item.buyQty, 0);
    const totalSellableAsins = purchaseOrder.items.reduce((acc, item) => acc + getSellableQty(item), 0);

    const totalNetProceeds = totalEstimatedRevenue - totalFees;
    const totalGrossProfit = totalNetProceeds - totalExpenses;
    const totalLandedCost = totalExpenses;

    const totalMargin = totalEstimatedRevenue ? totalGrossProfit * 100 / totalEstimatedRevenue : null;

    const totalROI = totalLandedCost ? totalGrossProfit * 100 / totalLandedCost : null;
    const buttonText = totalUnitsPurchased || purchaseOrder.status === "closed" ? "View Purchase Order" : "Begin Purchase Order";

    return {
        totalEstimatedRevenue,
        totalCOGs,
        totalWeight,
        totalShipping,
        totalProduction,
        totalExpenses,
        totalCases,
        totalUnitsPurchased,
        totalSellableAsins,
        totalNetProceeds,
        totalGrossProfit,
        totalMargin,
        totalFees,
        totalROI,
        buttonText,
        totalLandedCost,
    }
}

export type IPurchaseOrderColumnItem = ReturnType<typeof getPurchaseOrderItemColums>;

export const getPurchaseOrderItemColums = (item: IPurchaseOrderItem, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const id = item.id;
    const asin = item.asin;
    const title = item.housedAsin.title;
    const upc = item.housedAsin.upc;
    const vendorSKU = item.housedAsin.vendorSKU;
    const unitCost = +item.unitCost || +item.housedAsin.unitCost || 0;
    const bundle = item.bundle;
    const buyQty = item.buyQty;
    const sellable = getSellableQty(item);
    const casePack = item.casePack;
    const cases = getCases(item);
    const price = item.targetSoldPrice || +item.housedAsin.price || 0;
    const targetPrice = +item.targetPrice || 0;
    const totalCogs = getCostOfGoods(item);
    const productionCost = getPrepCost(item, prepCosts);
    const prepCost = item.housedAsin.prepCost;
    const shippingCost = getShippingCost(item, shippingCostDef);

    const landedCost = totalCogs + productionCost + shippingCost;

    const revenue = getEstimatedRevenue(item);
    const fees = getFees(item);
    const net = revenue - fees;
    const totalGross = net - landedCost;
    const gross = sellable ? totalGross / sellable : null;
    const roi = landedCost ? totalGross * 100 / landedCost : null;
    const margin = revenue ? totalGross * 100 / revenue : null;
    const weight = getWeight(item);

    const asinCost = unitCost * buyQty;

    return {
        id,
        asin,
        title,
        upc,
        vendorSKU,
        unitCost,
        bundle,
        buyQty,
        sellable,
        casePack,
        cases,
        price,
        targetPrice,
        totalCogs,
        prepCost,
        productionCost,
        shippingCost,
        landedCost,
        revenue,
        fees,
        net,
        totalGross,
        gross,
        roi,
        margin,
        weight,
        sku: item.housedAsin.sku,
        asinCost,
        brand: item.housedAsin.brand,
        salesCategory: item.housedAsin.salesCategory,
        salesRank: item.housedAsin.salesRank,
        order_type: item.orderType,
        notes: item.notes,
        group: item.group,
        storageType: item.housedAsin.storageType
    }
}

export const exportPurchaseOrder = (purchaseOrder: IPurchaseOrder, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const items = purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, shippingCostDef, prepCosts))
    const createdAt = purchaseOrder.createdAt;

    const overview = getPurchaseOrderOverview({
        purchaseOrder,
        shippingCostDef,
        prepCosts
    })

    const transformItem = (item: ReturnType<typeof getPurchaseOrderItemColums>) => {
      return {
        "PRODUCT DESCRIPTION": item.title,
        "ITEM CODE": item.vendorSKU,
        "UPC": item.upc,
        "UNIT QTY": item.buyQty,
        "CASE PACK": item.casePack,
        "ORDER CASE QTY": item.cases,
        "CURRENT UNIT COST": item.unitCost,
        "ASK PRICE": item.targetPrice,
        "RECEIVED PRICE": "",
        "TOTAL ASK COST EXTENDED": (item.buyQty || 1) * item.targetPrice,
        "TOTAL PO": overview.totalCOGs,
        "TOTAL CASES": overview.totalCases,
      }
    }
    const workbook = XLSX.utils.book_new()
    const headers = ["PRODUCT DESCRIPTION", "ITEM CODE", "UPC", "UNIT QTY", "CASE PACK", "ORDER CASE QTY", "CURRENT UNIT COST", "ASK PRICE", "RECEIVED PRICE", "TOTAL ASK COST EXTENDED", "TOTAL PO", "TOTAL CASES"] as const
    const shippingHeaderSheet = XLSX.utils.aoa_to_sheet([
        [{v: "COMPANY NAME:", t: "s", s: {font: {bold: true}}}],
        [{v: "ACCOUNT NUMBER:", t: "s", s: {font: {bold: true}}}],
        [{v: "BILL TO ADDRESS:", t: "s", s: {font: {bold: true}}}],
        [{v: "SHIP TO ADDRESS:", t: "s", s: {font: {bold: true}}}],
        [""],
    ])
    const header = headers.map((v) => {
        return {v, t: "s", s: {font: {bold: true}}}
    })
    const itemRows = items.map(transformItem)
    const productRows = itemRows.map((row) => (
        headers.map((key) => ({v: row[key], t: "s"})
    )))
    const sheet = XLSX.utils.sheet_add_aoa(shippingHeaderSheet, [header, ...productRows], {origin: "A6"})

    XLSX.utils.book_append_sheet(workbook, sheet)

    const name = `PO_${purchaseOrder.supplier}_${new Date(createdAt).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-')}.xlsx`
    XLSX.writeFile(workbook, name, {bookType: "xlsx"})
  }

export const exportPurchaseOrderIL = (purchaseOrder: IPurchaseOrder, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const transformItem = (item: ReturnType<typeof getPurchaseOrderItemColums>, i: number) => {
        const rawItem = purchaseOrder.items[i]
        return {
            ASIN: item.asin,
            TITLE: item.title,
            "BUY COST": item.unitCost,
            "LIST PRICE": (+rawItem.housedAsin.price || item.targetPrice || 0) * 1.2,
            "BUNDLE QUANTITY": item.bundle,
            "BUY QUANTITY": item.buyQty,
            "SELLABLE QUANTITY": item.sellable,
            "DATE PURCHASED": purchaseOrder.createdAt,
            SUPPLIER: purchaseOrder.supplier,
            CONDITION: "New",
            MSKU: item.sku,
            UPC: item.upc,
            "VENDOR SKU": item.vendorSKU,
            "CASE PACK": item.casePack,
            NOTES: item.notes || "",
            BRAND: item.brand || "",
            "AMZ LINK": `https://www.amazon.com/dp/${item.asin}`,
            "PRODUCT TITLE": item.title,
            "TARGET SELL PRICE": item.targetPrice,
            "NET PROCEEDS": item.net,
            WEIGHT: item.weight,
            "STORAGE TYPE": item.storageType || "",
            "PREP TYPE": item.prepCost,
        }
    }

    const items = purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, shippingCostDef, prepCosts))
    const itemRows = items.map(transformItem)
    const headers = ["ASIN", "TITLE", "BUY COST", "LIST PRICE", "BUNDLE QUANTITY", "BUY QUANTITY", "SELLABLE QUANTITY", "DATE PURCHASED", "SUPPLIER", "CONDITION", "MSKU", "UPC", "VENDOR SKU", "CASE PACK", "NOTES", "BRAND", "AMZ LINK", "PRODUCT TITLE", "TARGET SELL PRICE", "NET PROCEEDS", "WEIGHT", "STORAGE TYPE", "PREP TYPE"] as const
    const workbook = XLSX.utils.book_new()
    const header = headers.map((v) => {
        return {v, t: "s", s: {font: {bold: true}}}
    })
    const productRows = itemRows.map((row) => (
        headers.map((key) => ({v: row[key], t: "s"})
    ))
    )
    const sheet = XLSX.utils.aoa_to_sheet([header, ...productRows])
    XLSX.utils.book_append_sheet(workbook, sheet)
    const name = `PO_IL_${purchaseOrder.supplier}_${new Date(purchaseOrder.createdAt).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-')}.xlsx`

    XLSX.writeFile(workbook, name, {bookType: "xlsx"})
}

export const exportPurchaseOrder2D = (purchaseOrder: IPurchaseOrder, shippingCostDef?: IShippingCost, prepCosts?: IPrepCost[]) => {
    const transformItem = (item: ReturnType<typeof getPurchaseOrderItemColums>) => {
        return {
            SKU: item.sku,
            QTY: item.sellable,
            UNITS_PER_CASE: "",
            LOCATION: "",
            NOTES: item.notes || "",
            "MM-DD-YYYY": "",
            EXPIRY_TEXT: "",
            TITLE: item.title,
            UPC: item.upc
        }
    }
    const items = purchaseOrder.items.map((item) => getPurchaseOrderItemColums(item, shippingCostDef, prepCosts))
    const itemRows = items.map(transformItem)
    const headers = ["SKU", "QTY", "UNITS_PER_CASE", "LOCATION", "NOTES", "MM-DD-YYYY", "EXPIRY_TEXT", "TITLE", "UPC"] as const
    const workbook = XLSX.utils.book_new()
    const header = headers.map((v) => {
        return {v, t: "s", s: {font: {bold: true}}}
    })
    const productRows = itemRows.map((row) => (
        headers.map((key) => ({v: row[key], t: "s"})
    ))
    )
    const sheet = XLSX.utils.aoa_to_sheet([header, ...productRows])
    XLSX.utils.book_append_sheet(workbook, sheet)
    const name = `PO_2D_${purchaseOrder.supplier}_${new Date(purchaseOrder.createdAt).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-')}.xlsx`
    XLSX.writeFile(workbook, name, {bookType: "xlsx"})
}