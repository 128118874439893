import { USStates } from "./states"

export const yesNo = ['No', 'Yes']
export const contactMethods = ['Phone', 'Email']
export const statuses = ['Declined', 'Opened']

export const stateArea = ["National", ...Object.keys(USStates).sort()]

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const hours = Array(96)
  .fill(null)
  .map((_, i) => {
    const hour = Math.floor(i / 4)
    const minute = (i % 4) * 15
    const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour
    const ampm = hour < 12 ? 'AM' : 'PM'
    return `${displayHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}${ampm}`
  })

export const paymentTerms = ['Net Terms', 'Credit Card', 'Cash On Delivery', 'Wire / ACH']

export const carriers = ['Supplier Fleet', '3rd Party', 'Pick-Up']

export const discrepancyTimeframe = ['24 Hours', '48 Hours', '72 Hours', '1 Week', '2 Weeks', '1 Month']

export const amazonIndustries = [
  "Automotive",
  "Baby",
  "Beauty and Personal Care",
  "Woman's Fashion",
  "Men's Fashion",
  "Girl's Fashion",
  "Boy's Fashion",
  "Health and Household",
  "Grocery and Gourmet Food",
  "Home and Kitchen",
  "Industrial and Scientific",
  "Luggage",
  "Movies and Television",
  "Pet Supplies",
  "Software",
  "Sports and Outdoors",
  "Tools and Home Improvement",
  "Toys and Games",
  "Video Games",
  "Electronics",
  "Computers",
  "Smart Home",
  "Arts & Crafts",
  "Books",
].sort()

export const expenseCategories = ['Supplier', 'Inbound Freight', 'Payroll', 'Interest']

export const expenseFrequencies = ['One Time', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']

export const storageTypes = ["Standard", "Oversize", "Aerosol", "Apparel", "Flammable", "Standard", "Meltable"]

export const orderTypes = ["Backorder", "Stocked"]