import { AxiosInstance } from "axios"
import axios from "./axios"
import { IBuyer } from "types/buyers"

class BuyersApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getBuyers() {
    return this.axios.get('/housedBuyers').then((res) => res.data as IBuyer[])
  }
  
  async createBuyer(name: string) {
    return this.axios.post('/housedBuyers', {name}).then((res) => res.data as IBuyer)
  }
  
  async updateBuyer(id: string, name: string, isDefault: boolean) {
    return this.axios.put('/housedBuyers/'+id, {name, default: isDefault}).then((res) => res.data as IBuyer)
  }

  async deleteBuyer(id: string) {
    return this.axios.delete('/housedBuyers/'+id)
  }
}

const buyersApi = new BuyersApi()

export default buyersApi
