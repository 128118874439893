import { Row } from '@tanstack/react-table'
import buyersApi from 'api/buyers'
import AreYouSure from 'components/modals/AreYouSure'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import useAwaitableModal from 'hooks/useAwaitableModal'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { IBuyer } from 'types/buyers'
import { RowRenderer } from 'types/tables'

interface props {
  row: Row<IBuyer>
  setItems: Dispatch<SetStateAction<IBuyer[] | undefined>>
}

const BuyerItem: FC<props> = ({ row, setItems }) => {
  const item = row.original
  const [submitting, setSubmitting] = useState(false)
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, { header: 'Delete Buyer', description: 'Are you sure you want to delete this buyer?' })

  const makeDefault = () => {
    if (submitting) return
    setSubmitting(true)
    buyersApi
      .updateBuyer(item.id, item.name, true)
      .then(() => {
        setItems((old) => old && old.map((i) => ({ ...i, default: i.id === item.id })))
      })
      .finally(() => setSubmitting(false))
  }

  const onDelete = () => {
    if (submitting) return
    areYouSure().then(() => {
    setSubmitting(true)
    buyersApi
      .deleteBuyer(item.id)
      .then(() => {
        setItems((old) => old && old.filter((i) => i.id !== item.id))
      })
      .finally(() => setSubmitting(false))
    })
    }

  const cellRenderers: RowRenderer<IBuyer> = {
    name: (cell) => (
      <td key={cell.id} className="w-1/2" style={{...getCommonPinningStyles(cell.column)}}>
        <span 
        className={[
          "text-center rounded px-2 py-1 text-xs font-medium",
          item.default ? "bg-[#EAF2FF] text-[#0F56CA]" : "bg-[#ECFDF3] text-[#027A48]",
        ].asClass}
        >
          {item.name}
        </span>
      </td>
    ),
    actions: (cell) => (
      <td key={cell.id} className="w-1/2" style={{...getCommonPinningStyles(cell.column)}}>
        <div className="flex gap-2">
          {!item.default && <button className="button-secondary w-max !py-0.5" disabled={submitting} onClick={makeDefault}>Make default</button>}
          <button className="button-secondary w-max !py-0.5" disabled={submitting} onClick={onDelete}>Delete</button>
        </div>
      </td>
    ),
  }

  return (
    <tr className={['relative', submitting && 'animate-pulse'].asClass}>
      <AreYouSureModal />
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </tr>
  )
}

export default BuyerItem
