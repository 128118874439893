import { FC, useCallback, useMemo } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Table from 'components/tables/Table'
import withFiltering from 'contexts/Filter/wrapper'
import usePnL from 'hooks/usePnL'
import { IPnLTableRow } from 'types/pnl'
import Loader from 'components/loaders/Loader'
import { ColumnDef, Row } from '@tanstack/react-table'

const PnLPage: FC = () => {
  const {data, loading, syncState} = usePnL()
  
  const tableDef: ColumnDef<any>[] = useMemo(() => {
    return [...columnDef, ...(
      data.map((my) => ({
        id: my.month,
        header: my.month,
        meta: {
            filters: "number",
        } as const,
      }))
    )]
  }, [data])

  const tableRows = useMemo(() => {
    const salesData = data.map((my) => my.data.find((d) => d.parameter === 'Sales Revenue')!)
    const unitsData = data.map((my) => my.data.find((d) => d.parameter === 'Units Sold')!)
    const amazonFeesData = data.map((my) => my.data.find((d) => d.parameter === 'Amazon Fees')!)
    const grossMarginsData = data.map((my) => my.data.find((d) => d.parameter === 'Gross Profit')!)
    const costOfGoodsData = data.map((my) => my.data.find((d) => d.parameter === 'Cost of Goods Sold')!)
    const promotionsData = data.map((my) => my.data.find((d) => d.parameter === 'Promotions')!)
    const vatData = data.map((my) => my.data.find((d) => d.parameter === 'VAT')!)
    const adjustmentsData = data.map((my) => my.data.find((d) => d.parameter === 'Adjustments')!)
    const adsData = data.map((my) => my.data.find((d) => d.parameter === 'Ads')!)
    const loansData = data.map((my) => my.data.find((d) => d.parameter === 'Loans')!)
    const liquidationData = data.map((my) => my.data.find((d) => d.parameter === 'Liquidations')!)
    const otherFBACostsData = data.map((my) => my.data.find((d) => d.parameter === 'Other FBA Costs')!)
    const expensesData = data.map((my) => my.data.find((d) => d.parameter === 'Expenses')!)
    const productionCostsData = data.map((my) => my.data.find((d) => d.parameter === 'Production Cost per Unit Sold')!)
    return [{
      color: "orange",
      parameter: 'Sales Revenue',
      type: 'currency',
      data: salesData
    }, {
      color: "orange",
      parameter: 'Units Sold',
      type: 'number',
      data: unitsData
    }, 
    {
      color: "red",
      parameter: 'Amazon Fees',
      type: 'currency',
      otherTopLevelValues: [
        {
          type: "percentage",
          parameter: "Amazon Fees as % of Sales",
          value: amazonFeesData.map((d, i) => d.value / salesData[i].value)
        }
      ],
      data: amazonFeesData
    },
    {
      color: "red",
      parameter: 'Cost of Goods Sold',
      type: 'currency',
      data: costOfGoodsData
    },
    {
      color: "teal",
      parameter: 'Gross Profit',
      type: 'currency',
      data: grossMarginsData
    },
    {
      color: "red",
      parameter: 'Promotions',
      type: 'currency',
      data: promotionsData
    },
    {
      color: "orange",
      parameter: 'VAT',
      type: 'currency',
      data: vatData
    },
    {
      color: "red",
      parameter: 'Adjustments',
      type: 'currency',
      data: adjustmentsData
    },
    {
      color: "red",
      parameter: 'Ads',
      type: 'currency',
      otherTopLevelValues: [
        {
          type: "percentage",
          parameter: "Ads as % of Sales",
          value: adsData.map((d, i) => d.value / salesData[i].value)
        }
      ],
      data: adsData
    },
    /*{
      color: "red",
      parameter: 'Loans',
      type: 'currency',
      data: loansData
    },*/
    {
      color: "red",
      parameter: 'Liquidations',
      type: 'currency',
      data: liquidationData
    },
    {
      color: "red",
      parameter: 'Other FBA Costs',
      type: 'currency',
      otherTopLevelValues: [
        {
          type: "percentage",
          parameter: "% of Sales",
          value: otherFBACostsData.map((d, i) => d.value / salesData[i].value)
        }
      ],
      data: otherFBACostsData
    },
    {
      color: "red",
      parameter: 'Expenses',
      type: 'currency',
      data: expensesData
    }, 
    {
      color: "red",
      parameter: 'Production Cost per Unit Sold',
      type: 'currency',
      data: productionCostsData
    }
  ]
  }, [data])

  const renderRow = useCallback((row: Row<IPnLTableRow>, i: number) => <Item key={row.original.parameter} index={i} row={row} total={tableRows.length} />, [tableRows.length])

  return (
    <ContentLayout wrapperClass="relative">
      {!syncState.synced && (
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[1000] flex items-center justify-center">
        <div className="bg-white rounded-lg flex flex-col items-center justify-center p-4">
          <Loader />
          <div className="text-center mt-3 font-medium">Syncing Sales Data... {syncState.syncedUntil ? `Synced until: ${new Date(syncState.syncedUntil).toDateString()}` : ""}</div>
          <div className="text-center mt-1 text-slate-500">This is a long running operation, don't close this page</div>
        </div>
      </div>  
      )}
      <Table
        name="pnl"
        columns={tableDef}
        initialPinState={{
          left: ["expander", "parameter", "Total"]
        }}
        items={tableRows}
        renderRow={renderRow}
        loading={loading}
      />
    </ContentLayout>
  )
}

export default withFiltering(PnLPage, "pnl")
