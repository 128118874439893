import { FC, useCallback, useState } from 'react'
import useSuppliers from 'contexts/Suppliers/useSuppliers'
import SupplierRow from './Supplier'
import { Supplier } from 'types/suppliers'
import columnDef from './tableDef'
import ContentLayout from 'components/layout/Content'
import Table from 'components/tables/Table'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateFormModal from 'components/modals/CreateForm'
import suppliersApi from 'api/suppliers'
import useSelect from 'hooks/useSelect'
import useBulkDelete from 'hooks/useBulkDelete'
import useUserContext from 'contexts/User/useUserContext'
import { useNavigate } from 'react-router'
import { SuppliersExcelUpload } from 'utils/excelUpload/templates'
import BulkUpload from 'components/modals/BulkUpload'
import firebase from 'config/firebase'
import { uploadBytes, ref } from 'firebase/storage'
import useExcelUpload from 'hooks/useExcelUpload'
import Icon from 'assets/icons/iconset'
// import LockButton from 'components/buttons/LockButton'
import { Row } from '@tanstack/react-table'

const SupplierSettings: FC = () => {
  const user = useUserContext()
  const navigate = useNavigate()
  const { suppliers, setSuppliers, loading, loadNextPage, loadingNextPage } = useSuppliers()
  const select = useSelect()
  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, suppliersApi.deleteSuppliersBulk, { header: 'Delete Suppliers', description: 'Are you sure you want to delete these suppliers?' })
const excelUpload = useExcelUpload(SuppliersExcelUpload)
  const [importing, setImporting] = useState<'uploading' | 'processing' | false>(false)
  const [requestUpload, UploadModal] = useAwaitableModal(BulkUpload, { template: excelUpload, respondWithFile: true })  
  // const [locked, setLocked] = useState(true)

  const importExcel = useCallback(async () => {
    if (importing) return
    setImporting('uploading')
    return requestUpload()
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dir = "suppliers"
        const dest = `users/${user?.id}/${dir}/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          suppliersApi.uploadSupplierLeadsBulk(dest).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, requestUpload, user?.id])

  const createSupplier = useCallback((supplier: any) => {
    return suppliersApi.createSupplier(supplier as any)
  }, [])

  const formConfig = {
    onSubmit: createSupplier,
    config: {
      title: 'New Supplier',
      sections: [
        {
          title: 'Supplier Details',
          fields: [
            { id: 'name', name: 'Name', type: 'text' as const, required: true },
            { id: 'website', name: 'Website', type: 'text' as const, required: true },
          ],
        },
      ],
    },
  }

  const [addSupplier, AddSupplierComponent] = useAwaitableModal(CreateFormModal, formConfig)

  const onAdd = () => {
    addSupplier(formConfig).then((supplier) => {
      if (supplier) {
        setSuppliers((old) => old && [supplier, ...old])
      }
    })
  }

  const onRemove = useCallback(
    (supplier?: Supplier) => {
      if (!supplier) return
      setSuppliers((old) => old && old.filter((s) => s.id !== supplier.id))
    },
    [setSuppliers]
  )

  const onUpdate = useCallback(
    (supplier?: Supplier) => {
      if (!supplier) return
      setSuppliers((old) => old && old.map((s) => (s.id === supplier.id ? supplier : s)))
    },
    [setSuppliers]
  )

  const renderRow = useCallback((row: Row<Supplier>) => <SupplierRow key={row.original.id} row={row} onUpdate={onUpdate} onRemove={() => onRemove(row.original)} />, [onRemove, onUpdate])
  
  const canDeleteSomething = select.selected.length > 0 || select.allSelected

  return (
    <ContentLayout
      // afterSearch={<LockButton locked={locked} toggle={setLocked} />}
      underSearch={canDeleteSomething ? <button className="button-destructive w-max" onClick={onDelete} disabled={deleting}>Delete</button> : undefined}
      buttons={
        <div className="flex items-center gap-4">
          <button className="button-primary !pr-2" onClick={importExcel} disabled={!!importing}>
            <span>Import CSV</span>
            <Icon name="UploadSimple" className="w-5 h-5" />
          </button>
          <button className="button-secondary" onClick={onAdd}>
            New Supplier
          </button>
        </div>
      }
    >
      <AreYouSureModal />
      <AddSupplierComponent />
      <UploadModal />
      <Table name="suppliers" columns={columnDef} initialPinState={{left: ["name", "website"]}} items={suppliers} locked={false} renderRow={renderRow} loading={loading} loadingNext={loadingNextPage} onBottom={loadNextPage} select={select} />
    </ContentLayout>
  )
}

export default SupplierSettings
