import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import Tooltip from 'components/Tooltip'
import useTableContext from 'contexts/Table/useTableContext'
import { FC } from 'react'
import { usNumber } from 'utils/formatting'
import { Row } from '@tanstack/react-table'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { RowRenderer } from 'types/tables'
import TR from 'components/tables/TR'
import { RestockingItem } from 'types/restocking'

interface props {
  row: Row<RestockingItem>
}

const Item: FC<props> = ({ row }) => {
  const item = row.original
  const { extra } = useTableContext()

  const supplierNames = extra?.supplierNames || []
  const updateVelocities = extra?.updateVelocities

  const formattedInventoryBreakdown = Object.entries(item.amazonInventoryBreakdown)
    .map(([type, qty]) => {
      const formattedType = type
        .replace('Quantity', '')
        .replace(/([A-Z])/g, ' - $1')
        .replace(/^./, (str) => str.toUpperCase())
      return `${formattedType}: ${qty}`
    })
    .join('\n')

  const formattedInvAgeBreakdown = [
    'Inventory Age',
    `0-60 Days: ${item.fbaInventoryAgeBreakdown.invAge0To60Days || 0}`,
    `61-90 Days: ${item.fbaInventoryAgeBreakdown.invAge61To90Days || 0}`,
    `91-180 Days: ${item.fbaInventoryAgeBreakdown.invAge91To180Days || 0}`,
    `181-330 Days: ${item.fbaInventoryAgeBreakdown.invAge181To330Days || 0}`,
    `331-365 Days: ${item.fbaInventoryAgeBreakdown.invAge331To365Days || 0}`,
    `365+ Days: ${item.fbaInventoryAgeBreakdown.invAge365PlusDays || 0}`,
  ].join('\n')

  const cellRenderers: RowRenderer<RestockingItem> = {
    image: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <AmazonProductImage asin={item.asin} size={48} className="!w-12 !h-8 object-cover" />
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.asin}
      </td>
    ),
    url: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <a href={`https://www.amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
          <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
        </a>
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="max-w-[20rem] truncate">
        {item.title}
      </td>
    ),
    sku: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.sku}
      </td>
    ),
    suppliers: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} onBlur={(e) => e.stopPropagation()}>
        <Tooltip text={item.suppliers?.join(', ') || ''} position="left" className="z-[1000]">
          <div className="flex gap-1 items-center w-max">
            {!!item.suppliers?.length && <BadgeSelect selected={item.suppliers[0] || ''} badges={supplierNames || []} onSelect={() => {}} editable={false} />}
            {item.suppliers && item.suppliers?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.suppliers.length - 1}</span>}
          </div>
        </Tooltip>
      </td>
    ),
    upcs: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <Tooltip text={item.upcs?.join('\n') || ''} position="left" className="z-[1000]">
          <div className="flex gap-1 items-center w-max">
            {!!item.upcs?.length && <input type="text" readOnly value={item.upcs[0]} className="p-2" />}
            {item.upcs && item.upcs?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.upcs.length - 1}</span>}
          </div>
        </Tooltip>
      </td>
    ),
    rollingAvgCogs: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.rollingAvgCogs)}
      </td>
    ),
    estDailySalesVelocity: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="text-brand-primary hover:underline hover:text-brand-hover cursor-pointer" onClick={() => updateVelocities(item.sku)}>
        {usNumber(item.estDailySalesVelocity)}
      </td>
    ),
    profitPerSale: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.profitPerSale < 0 ? 'text-red-500' : item.profitPerSale > 0 ? 'text-green-500' : ''}>
        ${usNumber(item.profitPerSale)}
      </td>
    ),
    forecastedProfit: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.forecastedProfit)}
      </td>
    ),
    margin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.margin)}%
      </td>
    ),
    roi: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.roi)}%
      </td>
    ),
    daysUntilReorder: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.daysUntilReorder}
      </td>
    ),
    daysOfInventoryInAmazon: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.daysOfInventoryInAmazon}
      </td>
    ),
    qtyToReorderOnRedYellow: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {0}
      </td>
    ),
    fbaInventory: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <Tooltip className="leading-[200%]" text={formattedInventoryBreakdown} position="left-start">
          {usNumber(item.fbaInventory, 0)}
        </Tooltip>
      </td>
    ),
    ytdInStockRate: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(0)}%
      </td>
    ),
    missedDailyProfit: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(0)}
      </td>
    ),
    missedMonthlyProfit: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(0)}
      </td>
    ),
    missedYearlyProfit: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(0)}
      </td>
    ),
    prepCosts: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} onBlur={(e) => e.stopPropagation()}>
        <Tooltip text={item.prepCosts?.join(', ') || ''} position="left" className="z-[1000]">
          <div className="flex gap-1 items-center w-max">
            {!!item.prepCosts?.length && <BadgeSelect selected={item.prepCosts[0] || ''} badges={extra?.prepCosts || []} onSelect={() => {}} editable={false} />}
            {item.prepCosts && item.prepCosts?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.prepCosts.length - 1}</span>}
          </div>
        </Tooltip>
      </td>
    ),
    historicalDaysOfSupply: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <Tooltip className="leading-[200%]" text={formattedInvAgeBreakdown} position="left-start">
          {usNumber(item.historicalDaysOfSupply, 0)}
        </Tooltip>
      </td>
    ),
    fbaMinimumInventoryLevel: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.fbaMinimumInventoryLevel, 0)}
      </td>
    ),
    lowestUnitLevelToReorder: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {0}
      </td>
    ),
    targetUnitsStock: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {0}
      </td>
    ),
    qtyAvailable: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {0}
      </td>
    ),
    estRecommendedReorderCogs: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${0}
      </td>
    ),
    overUnderBuyingValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${0}
      </td>
    ),
    weightValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.weightValue)} lbs
      </td>
    ),
    purchaseOrderValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${0}
      </td>
    ),
    inboundOrderValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.inboundOrderValue)}
      </td>
    ),
    warehouseOrderValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.warehouseOrderValue)}
      </td>
    ),
    supplyChainValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.supplyChainValue)}
      </td>
    ),
    amazonInventoryValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.amazonInventoryValue)}
      </td>
    ),
    totalValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.totalValue)}
      </td>
    ),
    workingOrderQty: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.workingOrderQty, 0)}
      </td>
    ),
    inboundOrderQty: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.inboundOrderQty, 0)}
      </td>
    ),
    warehouseOrderQty: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.warehouseOrderQty, 0)}
      </td>
    ),
    supplyChainQty: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.supplyChainQty, 0)}
      </td>
    ),
    amazonInventory: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.amazonInventory, 0)}
      </td>
    ),
    totalQty: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.totalQty, 0)}
      </td>
    ),
  }

  return <TR>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default Item
