import { ColumnDef } from "@tanstack/react-table";
import { IWarehouseInboundShipment } from "types/warehouse";

export const columnDef: ColumnDef<IWarehouseInboundShipment>[] = [
    {
        id: "createdAt",
        header: "Processed Date",
        meta: {
            filters: "string",
        }
    },
    {
        id: "title",
        header: "Title",
        meta: {
            filters: "string",
        }
    },
    {
        id: "supplier",
        header: "Supplier",
        meta: {
            queryField: "supplier.name",
            filters: "string",
        }
    },
    {
        id: "asins",
        header: "Unique ASINs",
        meta: {
            filters: "number",
        }
    },
    {
        id: "units",
        header: "Units",
        meta: {
            filters: "number",
        }
    },
    {
        id: "status",
        header: "Status",
        meta: {
            filters: "string",
        }
    },
    {
        id: "eta",
        header: "Expected Delivery Date",
        meta: {
            filters: "date",
        }
    }
];