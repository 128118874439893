import { AxiosInstance } from "axios"
import axios from "./axios"
import { IPnLResponseDataPoint } from "types/pnl"
import { IPeriodFilter } from "types/tableFiltering"

class PnLApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  async getSyncStatus() {
    return this.axios.get('/pnl/sync/status').then((res) => res.data as {synced: boolean, syncedUntil: string})
  }

  async sync() {
    // long running operation, firebase hosting redirect fails after a minute
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'
    const url = `${base}/pnl/sync`

    return this.axios.get(url).then((res) => res.data as {synced: boolean, syncedUntil: string})
  }

  async getDashboard(params: IPeriodFilter) {
    // long running operation, firebase hosting redirect fails after a minute
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'
    const url = `${base}/pnl`
    return this.axios.get(url, {params}).then((res) => res.data as IPnLResponseDataPoint[])
  }
}

const pnlApi = new PnLApi()

export default pnlApi