import axiosBase, { AxiosError } from 'axios'
import firebase from '../config/firebase'
import { signOut } from 'firebase/auth'
import { handleError } from 'helpers/errors'

const axios = axiosBase.create({
   baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:5001/third-party-profits/us-central1/api' : 'https://app.thirdpartyprofits.com/api'
})

axios.interceptors.request.use(async (config) => {
    const bearer = await firebase.auth.currentUser?.getIdToken()

    config.headers.setAuthorization(`Bearer ${bearer}`)

    return config
})

axios.interceptors.response.use((res) => {
    return res
}, async (err: AxiosError<({ type?: string} & Record<string, unknown>) | string>) => {
    if (err.status === 401) {
      await signOut(firebase.auth)
      return
    } else if (err.response?.data && typeof err.response?.data !== "string" && err.response.data.type) {
      throw err.response.data
    } else {
      handleError(err.response?.data as string || "An unknown error occurred")
      throw err
    }
})

export default axios
