import { FC } from "react";

interface props {
    title: string;
    value?: string;
    className?: string
}

const DashboardTile: FC<props> = ({title, value, className = ""}) => {
    return (
        <div className={["w-[180px] flex flex-col h-min p-4 gap-1 rounded-lg border shrink-0 border-border-primary grow min-w-[180px]", className].join(" ")}>
            <span className="text-sm font-medium text-gray-100">{title}</span>
            <span className="text-xl font-bold text-white">{value ?? "..."}</span>
        </div>
    )
}

export default DashboardTile;