import useCatalogue from 'hooks/useCatalogue'
import { FC, useCallback, useState } from 'react'
import Item from './components/Item'
import useAwaitableModal from 'hooks/useAwaitableModal'
import catalogApi from 'api/catalog'
import { CatalogItem } from 'types/catalog'
import useExcelUpload from 'hooks/useExcelUpload'
import { excelUploads } from 'utils/excelUpload'
import BulkUpload from 'components/modals/BulkUpload'
import Loader from 'components/loaders/Loader'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Table from 'components/tables/Table'
import withFiltering from 'contexts/Filter/wrapper'
// import LockButton from 'components/buttons/LockButton'
import Dashboard from './components/Dashboard'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { Row } from '@tanstack/react-table'

const CataloguePage: FC = () => {
  const { sync, syncState, items, syncing, setItems, loadNextPage, loadingNextPage, dashboard, setDashboard } = useCatalogue()
  const { prepCosts } = usePrepCosts()
  const excelUpload = useExcelUpload(excelUploads.CatalogueExcelUpload)
  const modalConfig = { template: excelUpload, additionalInfo: 'values other than SKU are optional - keeping them blank will delete the stored values' }
  const [uploadBulk, BulkUploadModal] = useAwaitableModal(BulkUpload, modalConfig)
  const [uploadingBulk, setUploadingBulk] = useState(false)

  const { supplierNames } = useSupplierNames()

  const handleBulkUpload = useCallback(
    (data: { sku: string; asinCost?: number; supplier?: string; prepCost?: string }[]) => {
      if (uploadingBulk) return
      setUploadingBulk(true)
      catalogApi
        .bulkUpdate(data)
        .then(() => {
          setItems((old) =>
            old?.map((item) => {
              const match = data.find((d) => d.sku === item.sku)
              if (!match) return { ...item }
              return { ...item, asinCost: match.asinCost || 0}
            })
          )
        })
        .finally(() => setUploadingBulk(false))
    },
    [setItems, uploadingBulk]
  )

  const onBulkUpload = async (e: any) => {
    uploadBulk(modalConfig)
      .then(({ result }) => {
        if (!result) return
        const transformed = (result as any[]).map((item) => ({ sku: item.SKU, asinCost: item['Asin Cost (optional)'], supplier: item['Supplier (optional)'], prepCost: item['Prep Cost (optional)'] }))
        handleBulkUpload(transformed)
      })
      .catch(() => {})
  }

  const onFileChange = async (e: any) => {
    const file = e.target.files[0]
    if (!file) return
    excelUpload.load(file)
  }

  const renderRow = useCallback((row: Row<CatalogItem>) => <Item key={row.original.id} row={row} setDashboard={setDashboard} />, [setDashboard])

  return (
    <div className="w-full h-full flex flex-col overflow-hidden  bg-surface-light">
      <Dashboard dashboard={dashboard} />
      <ContentLayout
        wrapperClass="relative"
        // afterSearch={<LockButton locked={locked} toggle={setLocked} />}
        buttons={
          <>
            {syncState !== undefined && (
              <div className="flex items-center gap-3">
                <span className="text-sm font-semibold text-base-500">Last Synced At: {syncState ? syncState.toLocaleDateString() : 'Never'}</span>
                <button className="button-secondary" disabled={syncing} onClick={() => sync()}>
                  Refresh From Amazon
                </button>
                <div>
                  <input type="file" className="hidden" accept=".xlsx" onInput={onFileChange} />
                  {!uploadingBulk ? (
                    <button className="button-secondary" disabled={syncing || uploadingBulk} onClick={onBulkUpload}>
                      Bulk Upload Data
                    </button>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            )}
          </>
        }
      >
        {<BulkUploadModal />}
        <Table
          name="catalog"
          columns={columnDef}
          initialPinState={{
            left: ['image', 'asin', 'url', 'name'],
          }}
          items={items}
          locked={false}
          renderRow={renderRow}
          loading={!items || syncState === undefined || syncing}
          loadingNext={loadingNextPage}
          onBottom={loadNextPage}
          disabled={syncing}
          extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p.name) }}
        />
        {syncing && (
          <div className="w-full h-full flex items-center justify-center gap-4 absolute inset-0 z-[1000] backdrop-blur-sm">
            <div className="px-4 py-2 rounded-md bg-white flex items-center gap-4 drop-shadow-lg">
              <Loader />
              <span className="text-sm font-semibold text-base-500">Syncing with Amazon...</span>
            </div>
          </div>
        )}
      </ContentLayout>
    </div>
  )
}

export default withFiltering(CataloguePage, 'catalog-page')
