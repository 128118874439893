import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import purchaseOrdersApi from 'api/purchaseOrders'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { WorkingPurchaseOrderItem } from 'types/purchaseOrders'
import { IApiFilter } from 'types/tableFiltering'

const queryFn = async ({ pageParam: page, queryKey }: { pageParam: number; queryKey: [string, IApiFilter] }) => {
  return purchaseOrdersApi.getWorkingPurchaseOrders({ page, ...queryKey[1] })
}

const overviewQueryFn = async ({queryKey}: {queryKey: [string, IApiFilter]}) => {
  return purchaseOrdersApi.getWorkingPurchaseOrdersOverview(queryKey[1])
}

const useWorkingPOs = () => {
  const filter = useFilteringContext()
  const { search, ordering, opFilters: filters } = filter

  const queryKey = useMemo(
    () =>
      [
        'working-purchase-orders',
        {
          search,
          ordering,
          filters,
        },
      ] as [string, IApiFilter],
    [search, ordering, filters]
  )

  const {
    data,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage: hasMore,
  } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
  })

  const {
    data: overview,
    isRefetching,
    refetch: refetchOverview,
  } = useQuery({
    queryKey: [
      'working-purchase-orders-overview',
      {
        filters,
      },
    ],
    queryFn: overviewQueryFn,
  })

  const loadingNextPage = isFetchingNextPage

  const items = useMemo(() => (data ? [...data.pages.flatMap((p) => p.items)] : undefined), [data])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<WorkingPurchaseOrderItem[] | undefined>> = useCallback(
    (action) => {
      qc.setQueryData(queryKey, (old: typeof data) => {
        if (!old) return old
        refetchOverview({ cancelRefetch: true })
        if (action instanceof Function) {
          return {
            ...old,
            pages: old.pages.map((p) => ({
              ...p,
              items: action(p.items),
            })),
          }
        }
        if (!action) {
          refetch()
        } else {
          return {
            ...old,
            pages: old.pages.map((p, i) => ({
              ...p,
              items: p.items.map(() => action.shift()),
            })),
          }
        }
      })
    },
    [qc, queryKey, refetch, refetchOverview]
  )

  return {
    overview: isRefetching ? undefined : overview,
    load: refetch,
    loadNextPage: fetchNextPage,
    hasMore,
    setItems,
    loadingNextPage,
    items,
  }
}

export default useWorkingPOs
