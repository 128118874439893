import React, {FC} from 'react'
import Icon from "../../../../assets/icons/iconset";
import Product from './components/Product'
import {useParams} from "react-router";
import useTitle from "../../../../contexts/Title/useTitle";




const InboundReceiving: FC = () => {

    // const id = useParams<{ id: string }>().id as string

    const data = {
        id: '1234',
        createdAt: '1234',
        updatedAt: '1234',
        deletedAt: '1234',
        title: "Gannick's Care Dogs Bitter Apple No Chew Spray 8oz",
        asin: 34876483438,
        casePack: 12,
        budleQty: 1,
        unitsOrder: 2.400,
        invoiceUnitQty: 2.400,
        receivedUnitQty: 2.004,
        discrepancyUnitQty: 396,
        casesOrdered: 200,
        invoicedCaseQty: 2.400,
        receivedCaseQty: 2.004,
        discrepancyCaseQty: 33,
        sellableAsinsReceived: 2.004,
        unitCost: 4.18,
        discrepancyAmount: 1.66518,
        prepInstructions: "Labeling",
        notes: "Finsku",
        upcCode: 72527273070,
        vendorSku: 40320423940230,
        msku: "HG2343423423423",
        discrepancyType: "HG2343423423423"
    }

    useTitle('__back2__Inbound Receiving')
    return (
        <div className='flex flex-col w-full h-full'>
            <div className='flex justify-end my-4 mr-6 space-x-4'>
                <button className="button-secondary">
                    <Icon name="Plus" className="w-4 h-4"/>
                    Request Credit
                </button>
                <button className="button-secondary">
                    <Icon name="Plus" className="w-4 h-4"/>
                    Add Back to PO
                </button>
                <button className="button-secondary">
                    <Icon name="Plus" className="w-4 h-4"/>
                    Add to Pallet
                </button>
                <button className="button-primary">
                    <Icon name="Check" className="w-4 h-4"/>
                    Complete
                </button>
            </div>
            <Product product={data}/>
        </div>
    )

}

export default InboundReceiving
