import { Row } from '@tanstack/react-table'
import BadgeSelect from 'components/BadgeSelect'
import Checkbox from 'components/Checkbox'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TR from 'components/tables/TR'
import useTableContext from 'contexts/Table/useTableContext'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { RowRenderer } from 'types/tables'
import { IWarehouseInboundShipment } from 'types/warehouse'

interface props {
  row: Row<IWarehouseInboundShipment>
}

const StatusBadge: FC<{ status: 'inbound' | 'back-order' }> = ({ status }) => {
  if (status === 'inbound') {
    return <span className="bg-[#ECFDF3] text-[#027A48] text-xs px-2 py-1 rounded">Inbound</span>
  }

  return <span className="bg-[#FFF4EA] text-[#F0A30D] text-xs px-2 py-1 rounded">Back Order</span>
}

const Item: FC<props> = ({ row }) => {
  const shipment = row.original
  const {extra, select} = useTableContext()
  const navigate = useNavigate()

  const onClick = () => {
    navigate(`/app/warehouse/inbound/${shipment.id}`)
  }

  const isChecked = !!select?.selected.includes(shipment.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<IWarehouseInboundShipment> = {
    selection: (cell) => (
      !!select && (
        <td key={cell.id} onClick={(e) => {
            e.stopPropagation()
            select?.onSelectClick(shipment.id)
          }} style={{...getCommonPinningStyles(cell.column)}}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      )),
    createdAt: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <input type="date" value={shipment.createdAt.split('T')[0]} readOnly />
      </td>
    ),
    title: (cell) => <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{shipment.title}</td>,
    supplier: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <BadgeSelect selected={shipment.supplier.name} badges={extra.supplierNames} onSelect={() => {}} editable={false} />
      </td>
    ),
    asins: (cell) => <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{shipment.asins}</td>,
    units: (cell) => <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{shipment.units}</td>,
    status: (cell) => (
      <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>
        <StatusBadge status={shipment.status} />
      </td>
    ),
    eta: (cell) => <td key={cell.id} style={{...getCommonPinningStyles(cell.column)}}>{shipment.eta ? <input type="date" value={shipment.eta.split('T')[0]} readOnly /> : 'N/A'}</td>,
  }

  return (
    <TR onClick={onClick} className="cursor-pointer">
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Item
