import { FC, useCallback } from 'react'
import Item from './components/Item'
import { AsinEntryItem } from 'types/housedAsins'
import housedAsinsApi from 'api/housedAsins'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Loader from 'components/loaders/Loader'
import Table from 'components/tables/Table'
import useAwaitableModal from 'hooks/useAwaitableModal'
import useSelect from 'hooks/useSelect'
import useBulkDelete from 'hooks/useBulkDelete'
import useBulkAction from 'hooks/useBulkAction'
import AddAsinEntryModal from './components/AddAsinEntryModal'
import usePrepCosts from 'hooks/usePrepCosts'
// import LockButton from 'components/buttons/LockButton'
import useSupplierNames from 'hooks/useSupplierNames'
import useAsinEntries from 'hooks/useAsinEntries'
import withFiltering from 'contexts/Filter/wrapper'
import { Row } from '@tanstack/react-table'
import useBuyers from 'hooks/useBuyers'

const AsinEntry: FC = () => {
  const { asinEntries, setAsinEntries, importing, importExcel, loadAsinEntriesNextPage, loadingNextPage, loading } = useAsinEntries()
  const { buyers: availableBuyers } = useBuyers()
  const select = useSelect()
  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, housedAsinsApi.deleteAsinEntriesBulk, {
    header: 'Delete Asin Entries',
    description: 'Are you sure you want to delete these Asin entries?',
  })
  // const [locked, setLocked] = useState(true)
  const {prepCosts} = usePrepCosts()
  
  const {supplierNames} = useSupplierNames();

  const [onPushToHoused, pushingToHoused] = useBulkAction(select, housedAsinsApi.asinEntriesPushToHoused, "/app/housed/housed")

  const [addAsinEntry, CreateAsinEntryModal] = useAwaitableModal(AddAsinEntryModal, undefined)

  const onAdd = () => {
    addAsinEntry(undefined).then((entry) => {
      if (entry) {
        setAsinEntries((old) => old && [entry, ...old])
      }
    })
  }

  const onEdit = useCallback(
    (entry?: AsinEntryItem) => {
      if (entry) {
        setAsinEntries((old) => old && old.map((e) => (e.id === entry.id ? entry : e)))
      }
    },
    [setAsinEntries]
  )

  const onRemove = useCallback(
    (id: string) => {
      setAsinEntries((old) => old && old.filter((item) => item.id !== id))
    },
    [setAsinEntries]
  )

  const renderRow = useCallback((row: Row<AsinEntryItem>) => <Item key={row.original.id} row={row} onDone={onEdit} onRemove={onRemove} />, [onRemove, onEdit])

  const bulkInProgress = deleting || pushingToHoused
  const canDoBulk = select.selected.length > 0 || select.allSelected

  return (
    <ContentLayout
      // afterSearch={<LockButton locked={locked} toggle={setLocked} />}
      underSearch={
        canDoBulk ? (
          <div className="flex gap-4 items-center">
            <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
              Delete
            </button>
            <button className="button-primary w-max" onClick={onPushToHoused} disabled={bulkInProgress}>
              Push To Housed
            </button>
          </div>
        ) : undefined
      }
      buttons={
        <div className="flex items-center gap-3">
          <button className="button-primary" onClick={onAdd}>
            New Entry
          </button>
          {!importing ? (
            <button className="button-secondary" onClick={importExcel}>
              Import CSV
            </button>
          ) : (
            <Loader size={24} />
          )}
        </div>
      }
    >
      <AreYouSureModal />
      <CreateAsinEntryModal />
      <Table 
      name="asin-entry"
      columns={columnDef}
      extra={{prepCosts, supplierNames, availableBuyers}}
      locked={false}
      items={asinEntries} 
      renderRow={renderRow} 
      loading={loading || !asinEntries} loadingNext={loadingNextPage} onBottom={loadAsinEntriesNextPage} select={select} />
    </ContentLayout>
  )
}

export default withFiltering(AsinEntry, "asin-entry")
